import React from 'react';
import styled from 'styled-components';
import {Checkbox, Radio, Space, Typography} from 'antd';

import * as routineTypes from '@/modules/skinschool/types';
import * as types from '@/modules/customers/types';
import * as styles from '@/styles/globals';

interface PropType {
  routineActions: Array<routineTypes.CustomerRoutineAction>;
  diaryActions: types.DiaryRoutineAction[];
}

export const Singular: React.FC<PropType> = ({
  routineActions,
  diaryActions,
  ...props
}) => {
  return (
    <Space direction="vertical">
      {routineActions.map((item) => (
        <Space key={item.action.slug}>
          <Radio disabled defaultChecked={getDiaryActionValue(item.action.slug, diaryActions) !== null}>
            <RoutineActionName>{item.action.name}</RoutineActionName>
          </Radio>
        </Space>
      ))}
    </Space>
  );
};

export const Multiple: React.FC<PropType> = ({
  routineActions,
  diaryActions,
  ...props
}) => {
  return (
    <Space direction="vertical">
      {routineActions.map((item) => (
        <Space key={item.action.slug}>
          <Checkbox disabled={true} checked={getDiaryActionValue(item.action.slug, diaryActions) !== null} />
          <RoutineActionName>{item.action.name}</RoutineActionName>
        </Space>
      ))}
    </Space>
  );
};

const getDiaryActionValue = (actionSlug: string, diaryActions: types.DiaryRoutineAction[]) => {
  const action = diaryActions.find((o) => o.action.slug === actionSlug);
  return action?.value || null;
};

const RoutineActionName = styled(Typography.Text)`
  ${styles.text.body16};
  color: ${styles.color.gray8};
`;
