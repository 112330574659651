import React from 'react';
import {
  Typography,
} from 'antd';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import {
  ButtonLink,
  ButtonLinkWhite,
} from '@/components/common/buttons';
import PageBreadcrumb from '@/containers/PageBreadcrumb';

const DeskIndex: React.FC = () => {
  return (
    <Container>

      <PageBreadcrumb title="홈" />

      <MainContainer>
        <img src="/images/service_skinschool/welcome.png" alt="" />

        <div className="welcomeContainer">
          <Typography.Text className="welcome">안녕하세요, 스킨스쿨 컨시어지입니다.</Typography.Text>

          <div>
            <ButtonLink style={{marginRight: 8}} href="/desk/customers">고객 목록 페이지로</ButtonLink>
            <ButtonLinkWhite href="/desk/routine-actions/main">루틴, 케어 관리 페이지로</ButtonLinkWhite>
          </div>
        </div>

      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  background-color: ${styles.color.white};
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 108px 0 113px;
  
  .welcomeContainer {
    padding: 48px 32px;
  }
  .welcome {
    ${styles.text.header22}
    color: ${styles.color.gray9};
    padding: 0 0 62px;
    display: block;
  }
`;

export default DeskIndex;
