import * as types from '../types/customer';

export const CustomerListState: types.CustomerListState = {
  count: 0,
  customers: [],
};

export const CustomerDetailState: types.CustomerDetailState = {
  item: null,
  detailError: null,
  isDetailFetching: false,
};
