import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Space,
  Modal,
  Row,
  Transfer,
  Table,
} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import useSWR from 'swr';
import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import qs from 'query-string';
import _ from 'lodash';

import {AddButton} from '@/components/common/buttons';
import * as styles from '@/styles/globals';
import {useUser} from '@/modules/authentications/hooks/auth';
import * as types from '@/modules/customers/types';
import * as assignmentsSetActions from '~modules/customers/actions/staff.customer.assignments.set';
import {PaginationResponse} from "@/globals/types/http";
import {useDispatch} from 'react-redux';

const appName = 'service_skinschool';
const channel = 'cafe24';

type PropType = {
  username: string;
};

const AssignToStaff: React.FC<PropType> = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [assignments, setAssignments] = useState<string[]>([]);

  const onToggleModal = (status = true) => {
    setIsVisible(status);
  };

  const onSubmit = () => {
    dispatch(assignmentsSetActions.action.request({
      appName,
      customers: assignments,
    }));
    // TODO: 강제 reload 가 아닌 방법으로 목록 변경할 것.
    setTimeout(() => {
      onToggleModal(false);
      window.location.reload();
    }, 1000);
  };

  return (
    <Space>
      <ButtonAddAction
        icon={<AddButtonIcon />}
        onClick={() => onToggleModal(true)}>고객 추가/변경</ButtonAddAction>

      <ModalContainer
        visible={isVisible}
        width={820}
        title="커스텀 고객목록 추가/변경"
        destroyOnClose={true}
        okText="목록 저장하기"
        onOk={onSubmit}
        cancelText="닫기"
        onCancel={() => onToggleModal(false)}
      >
        <Space direction="vertical">
          <ModalTitle>고객 추가/변경</ModalTitle>

          <Row>
            <TableTransfer onChange={setAssignments} />
          </Row>
        </Space>
      </ModalContainer>
    </Space>
  );
};

const ModalFooter: React.FC = () => {
  return <div>hello</div>;
};

const tableColumns = [
  {
    dataIndex: '',
    title: '고객 이름',
  },
  {
    dataIndex: '',
    title: '스킨스쿨 아이디',
  },
];

type TransferItemType = {
  key: string,
  uid: string,
  name: string,
  username: string,
}

type TableTransferType = {
  onChange: (value: string[]) => void;
};

const TableTransfer: React.FC<TableTransferType> = ({onChange}) => {

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [data, setData] = useState<TransferItemType[]>([]);
  const [leftTerm, setLeftTerm] = useState('');
  const [rightTerm, setRightTerm] = useState('');
  const [user,] = useUser();

  const allCustomers = useCustomers({
    appName,
    channel,
    page: 1,
    limit: 100,
    term: leftTerm,
    assignedTo: undefined,
  });

  const staffCustomers = useCustomers({
    appName,
    channel,
    page: 1,
    limit: 100,
    assigning: rightTerm,
    assignedTo: user?.username || '',
  });

  useEffect(() => {
    if (!allCustomers.data || !staffCustomers.data) {
      return;
    }
    const _allCustomers = allCustomers.data.results.map(({uid, username, extra}) => {
      return {key: uid, uid, name: extra?.privacy?.name || username, username};
    });
    setData([
      ...staffCustomers.data.results.map(({uid, username, extra}) => {
        return {key: uid, uid, name: extra?.privacy?.name || username, username};
      }),
      ..._allCustomers,
    ]);
    setTargetKeys(staffCustomers.data.results.map(({uid}) => uid));
  }, [allCustomers.data, staffCustomers.data]);

  const onSearch = (direction: 'left' | 'right', value: string) => {
    if (direction === 'left' && value.length > 1) {
      setLeftTerm(value);
    }
    if (direction === 'right' && value.length > 1) {
      setRightTerm(value);
    }
  };

  const _onChange = (value: string[]) => {
    setTargetKeys(value);
    onChange(value);
  };

  const debounceSearch = _.debounce(onSearch, 500);

  return (
    <Transfer<TransferItemType> dataSource={data || []}
                                showSearch
                                targetKeys={targetKeys}
                                onSearch={debounceSearch}
                                onChange={_onChange}
                                render={(item) => `${item.name} / ${item.username}`} />
  );
};

const fetcher = (url: string) => {
  return axios.get(url).then(res => res.data);
}

const useCustomers = (params: types.CustomerListParam) => {
  const query = `?${qs.stringify(snakecaseKeys(params))}`;
  const url = params.assignedTo !== '' ? `/@skinschool/customers/${query}` : null;
  const { data, error } = useSWR<PaginationResponse<types.Customer>>(url, fetcher);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

const ButtonAddAction = styled(AddButton)`
  ${styles.text.body14}
  color: ${styles.color.white};
  background-color: ${styles.color.primaryBlue};
  border-color: ${styles.color.primaryBlue};
  border-width: 1px;
  border-style: solid;
  padding: 4px 12px;
`;

const AddButtonIcon = styled(PlusCircleOutlined)`
  background-color: ${styles.color.green};
  color: ${styles.color.primaryBlue};
  ${styles.text.body16}
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

const ModalContainer = styled(Modal)``;

const ModalTitle = styled.div`
  ${styles.text.header16};
  color: ${styles.color.gray10};
`;

const ModalSectionTitle = styled.div`
  ${styles.text.body14};
  color: ${styles.color.gray9};
`;

export default AssignToStaff;
