import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";

import * as types from '../types/customer';

export const ENTITY_NAME = 'customers/FETCH_CUSTOMER_DETAIL';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.CustomerDetailParam, HttpResponse<types.Customer>, Error | HttpError>(ENTITY_NAME);

export type DetailActionType = AT<typeof action>;

export type ActionType = DetailActionType;
