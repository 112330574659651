import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  Typography,
  Breadcrumb as AntdBreadcrumb,
} from 'antd';
import {isString} from 'lodash';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import {$breadCrumbsRoute} from '~globals/selectors/route';

type PropType = {
  title?: string | React.ReactNode;
  subTitle?: string;
};

const PageBreadcrumb: React.FC<PropType> = ({
  title,
  subTitle,
                                            }) => {
  const navigate = useNavigate();
  const breadCrumbsRoute = useSelector($breadCrumbsRoute);

  const onClick = (to: string) => {
    navigate(to);
  };
  return (
    <Container>
      <div className="wrapper">
        <Breadcrumb>
          {breadCrumbsRoute.map((item, index) => (
            <Breadcrumb.Item key={index.toString()}>
              <Typography.Link onClick={() => onClick(item.path)}>{item.name}</Typography.Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <TitleContainer>
          {title && isString(title)
            ? <Typography.Title className="title" level={1}>{title}</Typography.Title>
            : title
          }
          {subTitle && <Typography.Text className="subtitle">{subTitle}</Typography.Text>}
        </TitleContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${styles.color.white};
  padding: 0;
  width: 100%;
  
  .wrapper {
    border-color: ${styles.color.gray4};
    border-width: 1px;
    border-style: solid;
  }
`;

const Breadcrumb = styled(AntdBreadcrumb)`
  padding: 16px 24px 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px 16px;
  width: 100%;

  .title {
    ${styles.text.header22}
    color: ${styles.color.gray9};
    display: block;
    margin: 0;
  }
  .subtitle {
    ${styles.text.body14}
    color: ${styles.color.gray7};
    display: block;
    margin: 0 0 0 12px;
  }
`;

export default PageBreadcrumb;
