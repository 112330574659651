import {css} from 'styled-components';

export const color = {
  primaryBlue: '#244CDB',
  subBlue1: '#597EF7',
  subBlue2: '#85A5FF',
  subBlue3: '#ADC6FF',
  subBlue4: '#D6E4FF',
  green: '#3DD598',
  red: '#FF575F',
  yellow: '#FFC542',
  white: '#FFFFFF',
  gray2: '#FAFAFA',
  gray3: '#F5F5F5',
  gray4: '#E8E8E8',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#262626',
  gray10: '#222222',
};

export const text = {
  header22: css`
    font-size: 22px;
    line-height: 30px;
    font-weight: 900;
  `,
  header20: css`
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  `,
  header18: css`
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  `,
  header16: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  `,
  header14: css`
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
  `,
  body18: css`
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  `,
  body16: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  `,
  body14: css`
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  `,
  body12: css`
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  `,
};
