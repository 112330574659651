import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError} from "~globals/types/http";

import * as actions from '../actions/customerroutineaction.delete';
import * as types from '../types/routine';
import {CustomerRoutineActionDeleteState} from '../states/routine';
import {ResetActionStateActionType} from '../actions/customerroutineaction.delete';

export default createReducer<types.CustomerRoutineActionDeleteState, actions.ActionType | ResetActionStateActionType>(CustomerRoutineActionDeleteState)
  .handleAction(actions.action.request, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isDeleting = true;
      draft.item = null;
    });
  })
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      draft.item = payload as types.PayloadDeleteSuccess;
      draft.error = null;
      draft.isDeleting = false;
    });
  })
  .handleAction(actions.action.failure, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const res = (payload as HttpError<any>).response;
      draft.item = null;
      draft.isDeleting = false;
      if (!res) {
        draft.error = {status: -1, data: payload};
      } else {
        draft.error = {status: res.status, data: res.data};
      }
    });
  })
  .handleAction(actions.resetResetActionState, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isDeleting = false;
      draft.item = null;
    });
  });
