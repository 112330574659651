import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";

import * as types from '../types/routine';

export const ENTITY_NAME = 'skinschool/MODIFY_ROUTINE_ACTION';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.ModifyRoutineActionPayload, HttpResponse<types.SimpleRoutineAction>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
