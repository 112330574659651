import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpPaginationResponse} from "~globals/types/http";

import * as actions from '../actions/routineaction.list';
import * as types from '../types/routine';
import {RoutineActionListState} from '../states/routine';

export default createReducer<types.RoutineActionListState, actions.ActionType>(RoutineActionListState)
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const data = (payload as HttpPaginationResponse<types.SimpleRoutineAction>).data;
      draft.count = data.count;
      draft.items = data.results;
    });
  });
