import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";

import * as types from '../types/diary';

export const BY_UID_ENTITY_NAME = 'customers/FETCH_DIARY_DETAIL_BY_UID';

export const byUid = createAsyncActionEntity<types.DiaryDetailByUidParam, HttpResponse<types.Diary>, Error | HttpError>(BY_UID_ENTITY_NAME);

export type DetailByUidActionType = AT<typeof byUid.action>;

export const BY_DATE_ENTITY_NAME = 'customers/FETCH_DIARY_DETAIL_BY_DATE';

export const byDate = createAsyncActionEntity<types.DiaryDetailByDateParam, HttpResponse<types.Diary>, Error | HttpError>(BY_DATE_ENTITY_NAME);

export type DetailByDateActionType = AT<typeof byDate.action>;

export type ActionType = DetailByUidActionType | DetailByDateActionType;
