import React from 'react';
import { Typography } from 'antd';

const Copyright = () => {
  return (
    <Typography.Text>
      {'Copyright © '}
      <Typography.Link color="inherit" href="https://mfitlab.com/">
        marketfit lab inc.
      </Typography.Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography.Text>
  );
};

export default Copyright;
