import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";

import * as types from '../types/customer';

export const ENTITY_NAME = 'customers/SYNC_CUSTOMER_CAFE24_DATA';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.SyncCustomerCafe24Param, HttpResponse<types.Customer>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
