import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Space,
  Typography,
  DatePicker,
  Button,
  Radio,
  List,
  Input,
  message,
  Modal,
} from 'antd';
import {ModalProps} from 'antd/lib/modal'
import dayjs from 'dayjs';

import * as styles from '@/styles/globals';
import {PrimaryButton} from '~components/common/buttons/Basic';
import * as types from '~modules/skinschool/types';

export type SubmitPayloadType = {
  routine: types.BaseRoutine;
  action: types.SimpleRoutineAction;
  dates: Array<null | Date>;
};

type PropType = {
  customerRoutine: types.CustomerRoutine;
  actions: types.SimpleRoutineAction[];
  onSubmit: (payload: SubmitPayloadType) => void;
  onCancel: () => void;
} & ModalProps;

const AddCustomerRoutineAction: React.FC<PropType> = ({
  customerRoutine,
  actions,
  onCancel,
  ...props
}) => {
  const [action, setAction] = useState<null | types.SimpleRoutineAction>(null);
  const [items, setItems] = useState<types.SimpleRoutineAction[]>([]);
  const [dates, setDates] = useState<Array<null | Date>>([null, null]);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const currentActions = new Set(customerRoutine.items.map((o) => o.action.slug));
    setItems(
      actions.filter((o) => !currentActions.has(o.slug))
    );
  }, [customerRoutine.items, actions]);

  const onSubmit = () => {
    if (!action || !isValid) {
      return;
    }
    props.onSubmit({
      routine: customerRoutine.routine,
      action,
      dates,
    });
  };

  const onPickingDate = (openDate: null | Date, closeDate: null | Date) => {
    const today = dayjs(new Date());
    if (openDate && !today.isSame(openDate, 'date') && openDate < today.toDate()) {
      message.error('시작일자는 과거로 지정할 수 없습니다.');
      setIsValid(false);
      return;
    }
    if (closeDate && !today.isSame(closeDate, 'date') && closeDate < today.toDate()) {
      message.error('종료일자는 과거로 지정할 수 없습니다.');
      setIsValid(false);
      return;
    }
    if (openDate && closeDate && openDate > closeDate) {
      message.error('종료일자는 시작날짜 이후여야 합니다.');
      setIsValid(false);
      return;
    }
    setIsValid(true);
    setDates([openDate, closeDate]);
  };

  const onSearch = (value: any) => {
    if (value.length < 1) { return; }
    setItems(
      actions.filter((o) => o.name.indexOf(value) !== -1)
    );
  };

  return (
    <Modal
      centered
      title="액션 추가"
      onCancel={onCancel}
      footer={<Footer
        isValid={isValid}
        action={action}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />}
      {...props}
    >
      <Container>
        <Section>
          <Typography.Title className="title" level={4}>날짜 설정</Typography.Title>
          <Typography.Text className="description">시작과 종료 날짜를 설정해주세요</Typography.Text>
        </Section>

        <DateContainer>
          <Space direction="vertical" className="dateItemContainer">
            <Typography.Title className="dateLabel" level={5}>시작 날짜</Typography.Title>
            <DatePicker
              onChange={(value: any) => {
                onPickingDate(value ? value.toDate() : value, dates[1]);
              }}
            />
          </Space>
          <Space direction="vertical" className="dateItemContainer">
            <Typography.Title className="dateLabel" level={5}>종료 날짜</Typography.Title>
            <DatePicker
              onChange={(value: any) => {
                onPickingDate(dates[0], value ? value.toDate() : value);
              }}
            />
          </Space>
        </DateContainer>

        <Section>
          <Typography.Title className="title" level={4}>액션 선택</Typography.Title>
          <Typography.Text className="description">추가할 액션을 선택하세요</Typography.Text>
        </Section>

        <ActionContainer>
          <Input.Search
            placeholder="이름으로 검색해보세요."
            allowClear
            onChange={(e) => {
              if (!e.target.value.length) {
                setItems(actions);
              }
            }}
            onSearch={onSearch}
          />

          <List
            dataSource={items}
            renderItem={item => (
              <List.Item onClick={() => setAction(item)} key={item.slug}>
                <div className="listItem">
                  <Radio checked={!!action && action.slug === item.slug} onClick={() => setAction(item)} />
                  <div className="name">{item.name}</div>
                  <div className="description">{item.description || ''}</div>
                </div>
              </List.Item>
            )}
          />
        </ActionContainer>

      </Container>
    </Modal>
  );
};

type FooterPropType = {
  isValid: boolean;
  action: any;
  onCancel: () => void;
  onSubmit: () => void;
} & React.ComponentProps<any>;

export const Footer: React.FC<FooterPropType> = ({isValid, action, onCancel, onSubmit}) => {
  return (
    <>
      <Button onClick={onCancel}>취소</Button>
      <PrimaryButton disabled={!isValid || !action} onClick={onSubmit}>액션을 추가합니다</PrimaryButton>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;

  .title {
    ${styles.text.header18}
    color: ${styles.color.gray9};
    margin: 0 12px 0 0 !important;
  }
  .description {
    ${styles.text.body14}
    color: ${styles.color.gray7};
  }
`;

const DateContainer = styled(Space)`
  margin-bottom: 24px;
  
  .dateItemContainer {
    margin-right: 14px;
    .dateLabel {
      margin: 0 !important;
    }
  }
`;

const ActionContainer = styled.div`
  border-color: c.$gray5;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 8px;

  .listItem {
    display: flex;
    align-items: center;

    .name {
      ${styles.text.body14}
      color: ${styles.color.gray8};
      margin-right: 10px;
      min-width: 100px;
    }
    .description {
      ${styles.text.body14}
      color: ${styles.color.gray8};
    }
  }
`;

export default AddCustomerRoutineAction;
