import {ActionType as AT, createAction} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";

import * as types from '../types/routine';

export const ENTITY_NAME_MIN = 'skinschool/CREATE_ROUTINE_MIN';

export const minActions = createAsyncActionEntity<types.CreateRoutineMinimumPayload, HttpResponse<types.Routine>, Error | HttpError>(ENTITY_NAME_MIN);

export type MinActionType = AT<typeof minActions.action>;

export const ENTITY_NAME_FULL = 'skinschool/CREATE_ROUTINE_FULL';

export const fullActions = createAsyncActionEntity<types.CreateRoutinePayload, HttpResponse<types.Routine>, Error | HttpError>(ENTITY_NAME_FULL);

export type FullActionType = AT<typeof fullActions.action>;

export const ENTITY_RESET_CREATE_STATE = 'skinschool/ENTITY_RESET_CREATE_STATE';

export const resetCreateStateAction = createAction(ENTITY_RESET_CREATE_STATE)();

export type ResetCreateStateActionType = AT<typeof resetCreateStateAction>;

export type ActionType = MinActionType | FullActionType | ResetCreateStateActionType;
