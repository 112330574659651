import { put, call } from 'redux-saga/effects';

import * as routineListActions from '../actions/routine.list';
import * as routineCreateActions from '../actions/routine.create';
import * as routineActionListActions from '../actions/routineaction.list';
import * as routineActionAddActions from '../actions/routineaction.add';
import * as customerRoutineListActions from '../actions/customerroutine.list';
import * as customerRoutineLockActions from '../actions/customerroutine.lock';
import * as customerRoutineActionDeleteActions from '../actions/customerroutineaction.delete';
import * as customerRoutineActionAddActions from '../actions/customerroutineaction.add';
import * as routineActionDeleteActions from '../actions/routineaction.delete';
import * as routineActionModifyActions from '../actions/routineaction.modify';
import * as api from '../apis/routine';

export function* fetchRoutines$(action: ReturnType<typeof routineListActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchRoutines, payload);

    yield put(routineListActions.action.success(result));
  } catch (e) {
    yield put(routineListActions.action.failure(e));
    console.log(e);
  }
}

export function* fetchRoutineActions$(action: ReturnType<typeof routineActionListActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchRoutineActions, payload);

    yield put(routineActionListActions.action.success(result));
  } catch (e) {
    yield put(routineActionListActions.action.failure(e));
    console.log(e);
  }
}

export function* fetchCustomerRoutines$(action: ReturnType<typeof customerRoutineListActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchCustomerRoutines, payload);

    yield put(customerRoutineListActions.action.success(result));
  } catch (e) {
    yield put(customerRoutineListActions.action.failure(e));
    console.log(e);
  }
}

export function* deleteCustomerRoutineAction$(action: ReturnType<typeof customerRoutineActionDeleteActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    yield call(api.deleteCustomerRoutineAction, payload);
    const {routine, action, customer} = payload;
    const result = {routine, action, customer};
    yield put(customerRoutineActionDeleteActions.action.success(result));
    yield put(customerRoutineListActions.action.request({
      appName: payload.appName,
      customer: payload.customer,
    }));
  } catch (e) {
    yield put(customerRoutineActionDeleteActions.action.failure(e));
    console.log(e);
  }
}

export function* addCustomerRoutineAction$(action: ReturnType<typeof customerRoutineActionAddActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.addCustomerRoutineAction, payload);
    yield put(customerRoutineActionAddActions.action.success(result));
    yield put(customerRoutineListActions.action.request({
      appName: payload.appName,
      customer: payload.customer,
    }));
  } catch (e) {
    yield put(customerRoutineActionAddActions.action.failure(e));
    console.log(e);
  }
}

export function* addActionToRoutine$(action: ReturnType<typeof routineActionAddActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.addActionToRoutines, payload);

    yield put(routineActionAddActions.action.success(result));
    yield put(routineActionListActions.action.request({
      appName: payload.appName,
    }));
  } catch (e) {
    yield put(routineActionAddActions.action.failure(e));
    console.log(e);
  }
}

type _createRoutineActionType = ReturnType<
  typeof routineCreateActions.minActions.action.request |
  typeof routineCreateActions.fullActions.action.request
>;
export function* createRoutine$(action: _createRoutineActionType) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.createRoutine, payload);

    yield put(routineCreateActions.minActions.action.success(result));
    yield put(routineCreateActions.fullActions.action.success(result));
    yield put(routineListActions.action.request({
      appName: payload.appName,
    }));
  } catch (e) {
    yield put(routineCreateActions.minActions.action.failure(e));
    yield put(routineCreateActions.fullActions.action.failure(e));
    console.log(e);
  }
}

export function* deleteRoutineAction$(action: ReturnType<typeof routineActionDeleteActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    yield call(api.deleteRoutineAction, payload);
    const {appName} = payload;
    const _payload = {appName};
    yield put(routineListActions.action.request(_payload));
    yield put(routineActionListActions.action.request(_payload));
  } catch (e) {
    yield put(routineActionDeleteActions.action.failure(e));
    console.log(e);
  }
}

export function* modifyRoutineAction$(action: ReturnType<typeof routineActionModifyActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.modifyRoutineAction, payload);
    yield put(routineActionModifyActions.action.success(result));

    const {appName} = payload;
    const _payload = {appName};
    yield put(routineListActions.action.request(_payload));
    yield put(routineActionListActions.action.request(_payload));
  } catch (e) {
    yield put(routineActionModifyActions.action.failure(e));
    console.log(e);
  }
}

export function* lockCustomerRoutine$(action: ReturnType<typeof customerRoutineLockActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    yield call(api.lockCustomerRoutines, payload);
    yield put(customerRoutineListActions.action.request({
      appName: payload.appName,
      customer: payload.customer,
    }));
  } catch (e) {
    yield put(customerRoutineLockActions.action.failure(e));
    console.log(e);
  }
}
