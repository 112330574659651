import styled from 'styled-components';

import * as vars from '@/styles/globals';

type StylePropType = {
  bgColor?: keyof typeof vars.color;
  borderColor?: keyof typeof vars.color;
};

export const ButtonLink = styled.a<StylePropType>`
  display: inline-block;
  background-color: ${vars.color.primaryBlue};
  border-color: ${vars.color.primaryBlue};
  //background-color: ${props => props.bgColor ? vars.color[props.bgColor] : vars.color.primaryBlue};
  //border-color: ${props => props.borderColor ? vars.color[props.borderColor] : vars.color.primaryBlue};
  border-width: 1px;
  border-style: solid;
  color: ${vars.color.white};
  link: ${vars.color.white};
  ${vars.text.body14}
  border-radius: 2px;
  padding: 5px 16px;
`;

export const ButtonLinkWhite = styled(ButtonLink)`
  background-color: ${vars.color.white};
  border-color: ${vars.color.gray5};
  color: ${vars.color.gray8};
  link: ${vars.color.gray8};
`;
