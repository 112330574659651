import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpPaginationResponse} from "~globals/types/http";

import * as types from '../types/routine';

export const ENTITY_NAME = 'skinschool/FETCH_CUSTOMER_ROUTINE_LIST';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.CustomerRoutineListParam, HttpPaginationResponse<types.CustomerRoutine>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
