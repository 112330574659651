import {createAsyncAction} from 'typesafe-actions';

export const createAsyncActionEntity = <R, S, F>(entity: string) => {
  const REQUEST = `${entity}_REQUEST`;
  const SUCCESS = `${entity}_SUCCESS`;
  const FAILURE = `${entity}_FAILURE`;
  const action = createAsyncAction(REQUEST, SUCCESS, FAILURE)<R, S, F>();
  return {
    action,
    REQUEST,
    SUCCESS,
    FAILURE,
  };
}
