import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";
import {AuthenticationResponse} from '~modules/authentications/types/auth';

export type LoginUsernamePayload = {
  username: string;
  password: string;
  next?: string;
};

export const ENTITY_NAME = 'authentications/LOGIN';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<LoginUsernamePayload, HttpResponse<AuthenticationResponse>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
