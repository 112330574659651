import React, {useState} from 'react';
import styled from 'styled-components';
import {
  Typography,
  Drawer,
  Empty,
  Spin,
  Space,
} from 'antd';
import {Dayjs} from 'dayjs';

import * as styles from '@/styles/globals';
import * as numberUtils from '@/globals/utils/number';
import * as types from '@/modules/customers/types';
import {useDiaryByDate} from '@/hooks/diary';

import Section from './Section';
import Indicator from './Indicator';

type PropType = {
  appName: string;
  customer: types.Customer;
  visible: boolean;
  onClose: () => void;
  width?: number;
};

const AppCheckList: React.FC<PropType> = ({
  appName,
  customer,
  visible,
  onClose,
  width = 256,
  ...props
}) => {
  const [date, setDate] = useState<Date>(new Date());
  const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false);
  const {diary, isLoading} = useDiaryByDate({date, appName, customer});

  const onChangeDate = (date: Dayjs) => {
    setIsVisibleDatePicker(false);
    setDate(date.toDate());
  };

  const _onCloseDrawer = () => {
    setIsVisibleDatePicker(false);
    onClose();
  };

  const calcProgressPercent = (diary: null | types.Diary) => {
    return !diary
      ? 0
      : numberUtils.calcPercent(diary.progressPoints?.progress, diary.progressPoints?.total);
  };

  return (
    <Container
      visible={visible}
      onClose={_onCloseDrawer}
      width={width}>
      <Indicator onChangeDate={onChangeDate}
                 isVisible={isVisibleDatePicker}
                 onChangeVisible={(value) => setIsVisibleDatePicker(value)} />

      {!diary && <Empty />}
      {isLoading && <Spin />}
      {!isLoading && diary && (
        <ContentContainer direction="vertical">
          <Progress>
            <Typography.Text>
              진행도 : {calcProgressPercent(diary)}%
            </Typography.Text>
          </Progress>

          <Space direction="vertical">
            <Section title="회원 루틴 체크 현황"
                     diary={diary}
                     items={diary.customerRoutines.filter((item) => item.routine.priority === 'primary')} />

            <Section title="회원 케어 체크 현황"
                     diary={diary}
                     items={diary.customerRoutines.filter((item) => item.routine.priority === 'secondary')} />
          </Space>
        </ContentContainer>
      )}
    </Container>
  );
};

const Container = styled(Drawer)``;

const ContentContainer = styled(Space)`
  margin-top: 20px;
  width: 100%;
`;

const Progress = styled(Space)`
  ${styles.text.header16}
  color: ${styles.color.gray10};
  padding: 21px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
`;

export default AppCheckList;
