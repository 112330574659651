import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError, HttpResponse} from "~globals/types/http";

import * as actions from '../actions/customer.detail';
import * as types from '../types/customer';
import {CustomerDetailState} from '../states/customer';

export default createReducer<types.CustomerDetailState, actions.ActionType>(CustomerDetailState)
  .handleAction(
    actions.action.request, (state) => {
      return produce(state, draft => {
        draft.isDetailFetching = true;
        draft.detailError = null;
        draft.item = null;
      });
    })
  .handleAction(
    actions.action.success, (state, action: actions.DetailActionType) => {
      const {payload} = action;
      return produce(state, draft => {
        const data = (payload as HttpResponse<types.Customer>).data;
        draft.detailError = null;
        draft.item = data;
        draft.isDetailFetching = false;
      });
    })
  .handleAction(
    actions.action.failure, (state, action: actions.DetailActionType) => {
      const {payload} = action;
      return produce(state, draft => {
        const res = (payload as HttpError<any>).response;
        draft.item = null;
        if (!res) {
          draft.detailError = {status: -1, data: payload};
        } else {
          draft.detailError = {status: res.status, data: res.data};
        }
        draft.isDetailFetching = false;
      });
    });
