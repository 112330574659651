const env = process.env;

export default {
  routes: {
    defaultPathAfterAuth: '/desk',
  },
  apiserver: {
    host: env.REACT_APP_APISERVER_HOST
  },
};
