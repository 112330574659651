import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  authorizationUri as cafe24AuthorizationUri,
  checkAuthorization as checkCafe24AppAuthorization
} from '@/modules/authentications/actions/cafe24.authorization';
import {
  $authUri,
  $currentMallApp,
  $requiredOAuthAuthorization,
} from '@/modules/authentications/selectors/cafe24';
import {Cafe24MallApplication} from '@/modules/authentications/types';

interface Cafe24AuthenticationReturnType {
  isRequired: boolean | null;
  currentMallApp: Cafe24MallApplication;
  authUri: string | null;
}

export const useCafe24Authentication = (): Cafe24AuthenticationReturnType => {
  const dispatch = useDispatch();

  const requiredOAuthAuthorization = useSelector($requiredOAuthAuthorization);
  const currentMallApp = useSelector($currentMallApp);
  const authUri = useSelector($authUri);

  useEffect(() => {
    if(requiredOAuthAuthorization === null && currentMallApp) {
      dispatch(checkCafe24AppAuthorization.action.request(currentMallApp.clientId));
      dispatch(cafe24AuthorizationUri.action.request(currentMallApp.clientId));
    }
  }, [requiredOAuthAuthorization, currentMallApp]);

  return {
    isRequired: requiredOAuthAuthorization,
    currentMallApp,
    authUri,
  };
};

