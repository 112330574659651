import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError, HttpResponse} from "~globals/types/http";

import * as actions from '../actions/routineaction.modify';
import * as types from '../types/routine';
import {RoutineModifyState} from '../states/routine';
import {SimpleRoutineAction} from '../types/routine';

export default createReducer<types.RoutineModifyState, actions.ActionType>(RoutineModifyState)
  .handleAction(actions.action.request, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isModifying = true;
      draft.item = null;
    });
  })
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      draft.error = null;
      draft.isModifying = false;
      draft.item = (payload as HttpResponse<types.RoutineAction | SimpleRoutineAction>).data;
    });
  })
  .handleAction(actions.action.failure, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const res = (payload as HttpError<any>).response;
      draft.isModifying = false;
      draft.item = null;
      if (!res) {
        draft.error = {status: -1, data: payload};
      } else {
        draft.error = {status: res.status, data: res.data};
      }
    });
  });
