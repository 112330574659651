import {ActionType as AT, createAction} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpPaginationResponse} from "~globals/types/http";

import * as types from '../types/customer';

export const ENTITY_NAME = 'customers/CUSTOMER_LIST';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.CustomerListParam, HttpPaginationResponse<types.Customer>, Error | HttpError>(ENTITY_NAME);

export type CustomerListActionType = AT<typeof action>;

export const UPDATE_CUSTOMER_DETAIL_ENTITY_NAME = 'custoemrs/UPDATE_CUSTOMER_DETAIL_ENTITY_NAME';

export const updateCustomerDetail = createAction(UPDATE_CUSTOMER_DETAIL_ENTITY_NAME)<types.Customer>();

export type UpdateCustomerDetailActionType = AT<typeof updateCustomerDetail>;

export type ActionType = CustomerListActionType | UpdateCustomerDetailActionType;
