import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError} from "~globals/types/http";

import * as actions from '../actions/routineaction.add';
import * as types from '../types/routine';
import {RoutineActionAddState} from '../states/routine';

export default createReducer<types.RoutineActionAddState, actions.ActionType>(RoutineActionAddState)
  .handleAction(actions.action.request, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isAdding = true;
    });
  })
  .handleAction(actions.action.success, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isAdding = false;
    });
  })
  .handleAction(actions.action.failure, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const res = (payload as HttpError<any>).response;
      draft.isAdding = false;
      if (!res) {
        draft.error = {status: -1, data: payload};
      } else {
        draft.error = {status: res.status, data: res.data};
      }
    });
  });
