import { put, call } from 'redux-saga/effects';

import * as actions from '../actions/diary.detail';
import * as api from '../apis/diary';

export function* fetchDiaryDetailByUid$(action: ReturnType<typeof actions.byUid.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchDiaryDetail, payload);

    yield put(actions.byUid.action.success(result));
  } catch (e) {
    yield put(actions.byUid.action.failure(e));
    console.log(e);
  }
}

export function* fetchDiaryDetailByDate$(action: ReturnType<typeof actions.byDate.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchDiaryDetailByDate, payload);

    yield put(actions.byUid.action.success(result));
  } catch (e) {
    yield put(actions.byUid.action.failure(e));
    console.log(e);
  }
}
