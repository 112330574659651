
import axios from 'axios';

import {LoginUsernamePayload} from '../actions/login';

export async function postAuthentication(payload: LoginUsernamePayload) {
  return axios.post('/api-token-auth/', payload);
}

export async function deleteAuthentication(payload: string) {
  return axios.delete(`/api/authentications/${payload}/`);
}

export async function fetchUserDetail(payload: string | null) {
  return axios.get(`/accounts/users/${payload || '@me'}/`);
}
