import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";
import {User} from '~modules/authentications/types/auth';

export const ENTITY_NAME = 'authentications/USER_DETAIL';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<string | null, HttpResponse<User>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
