import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError, HttpResponse} from "~globals/types/http";

import * as actions from '../actions/diary.detail';
import * as types from '../types/diary';
import {DiaryDetailState} from '../states/diary';

export default createReducer<types.DiaryDetailState, actions.ActionType>(DiaryDetailState)
  .handleAction(
    actions.byUid.action.request, (state) => {
      return produce(state, draft => {
        draft.isDetailFetching = true;
        draft.detailError = null;
        draft.item = null;
      });
    })
  .handleAction(
    actions.byUid.action.success, (state, action: actions.DetailByUidActionType) => {
      const {payload} = action;
      return produce(state, draft => {
        const data = (payload as HttpResponse<types.Diary>).data;
        draft.isDetailFetching = false;
        draft.detailError = null;
        draft.item = data;
      });
    })
  .handleAction(
    actions.byUid.action.failure, (state, action: actions.DetailByUidActionType) => {
      const {payload} = action;
      return produce(state, draft => {
        const res = (payload as HttpError<any>).response;
        draft.isDetailFetching = false;
        draft.item = null;
        if (!res) {
          draft.detailError = {status: -1, data: payload};
        } else {
          draft.detailError = {status: res.status, data: res.data};
        }
      });
    });
