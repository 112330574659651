import { put, call } from 'redux-saga/effects';

import * as customerListActions from '../actions/customer.list';
import * as customerDetailActions from '../actions/customer.detail';
import * as customerSyncActions from '../actions/customer.sync';
import * as staffCustomerAssignmentCreate from '../actions/staff.customer.assignments.set';
import * as staffCustomerAssignmentDelete from '../actions/staff.customer.assignment.delete';
import * as api from '../apis/customer';

export function* fetchCustomers$(action: ReturnType<typeof customerListActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchCustomers, payload);

    yield put(customerListActions.action.success(result));
  } catch (e) {
    yield put(customerListActions.action.failure(e));
    console.log(e);
  }
}

export function* fetchCustomerDetail$(action: ReturnType<typeof customerDetailActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchCustomerDetail, payload);

    yield put(customerDetailActions.action.success(result));
    yield put(customerListActions.updateCustomerDetail(result.data));
  } catch (e) {
    yield put(customerDetailActions.action.failure(e));
    console.log(e);
  }
}

export function* syncCustomerCafe24$(action: ReturnType<typeof customerSyncActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.syncCustomerCafe24, payload);

    yield put(customerListActions.updateCustomerDetail(result.data));
    yield put(customerDetailActions.action.success(result));
  } catch (e) {
    yield put(customerDetailActions.action.failure(e));
    console.log(e);
  }
}

export function* setStaffCustomerAssignments$(action: ReturnType<typeof staffCustomerAssignmentCreate.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    yield call(api.setCustomerAssignments, payload);
  } catch (e) {
    console.log(e);
  }
}
