import React from 'react';
import {Space, Typography} from 'antd';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import * as routineTypes from '@/modules/skinschool/types';
import * as customerTypes from '@/modules/customers/types';

import {
  Singular as SingularItem,
  Multiple as MultipleItem,
} from './RoutineActionItem';

interface PropType {
  title: string;
  diary: customerTypes.Diary,
  items: routineTypes.CustomerRoutine[];
}

const Section: React.FC<PropType> = ({
  title,
  diary,
  items,
}) => (
  <Space direction="vertical">
    <SectionTitle level={3}>{title}</SectionTitle>
    {items.map((item) => <RoutineActions key={item.routine.slug} customerRoutine={item} actions={diary.actions} />)}
  </Space>
);

const SectionTitle = styled(Typography.Title)`
  ${styles.text.header18}
  color: ${styles.color.gray10};
  padding: 30px 0 20px;
  margin: 0;
`;

const RoutineName = styled(Space)`
  ${styles.text.header16}
  color: ${styles.color.gray10};
  margin: 0;
  padding: 0 0 8px 0;
`;

interface RoutineActionsPropType {
  customerRoutine: routineTypes.CustomerRoutine;
  actions: customerTypes.DiaryRoutineAction[];
}

const RoutineActions: React.FC<RoutineActionsPropType> = ({
  customerRoutine,
  actions,
  ...props
}) => {
  const diaryActions = actions.filter((item) => item.action.routine === customerRoutine.routine.slug);

  return (
    <RoutineName direction="vertical">
      <Typography.Title level={4}>{customerRoutine.routine.name}</Typography.Title>

      {(customerRoutine.routine.checkKind === 'singular' || customerRoutine.routine.checkKind === 'yes_or_no')  && (
        <SingularItem routineActions={customerRoutine.items} diaryActions={diaryActions} />
      )}
      {customerRoutine.routine.checkKind === 'multiple' && (
        <MultipleItem routineActions={customerRoutine.items} diaryActions={diaryActions} />
      )}
    </RoutineName>
  );
};

export default Section;
