import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {isString} from 'lodash';

import {$currentUser} from '@/modules/authentications/selectors/auth';
import {getStorageData} from '@/globals/utils/storage.local';
import {AUTH_TOKEN_STORAGE_KEY} from '@/globals/utils/storage.keys';

import {User} from '../models/auth';

export const useIsAuthenticated = (nextRoute?: null | string): null | boolean => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);

  useEffect(() => {
    setIsAuthenticated(!!getStorageData(AUTH_TOKEN_STORAGE_KEY));
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated === false && isString(nextRoute)) {
      navigate(`/login?next=${nextRoute}`, {replace: true});
    }
  }, [isAuthenticated, nextRoute]);

  return isAuthenticated;
};

export const useUser = () => {
  const user = useSelector($currentUser);
  const [me, setMe] = useState<null | User>(null);

  useEffect(() => {
    setMe(user ? user : null);
  }, [user]);
  return [me];
};

export const useAppName = () => {
  const [user,] = useUser();
  const [appName, setAppName] = useState<null | string>(null);
  useEffect(() => {
    if (!user || !user.currentOrganization) { return; }

    setAppName(user.currentOrganization.organization.appName);
  }, [user]);

  return appName;
};
