import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import * as types from '@/modules/customers/types';
import * as listActions from '@/modules/customers/actions/customer.list';
import {buildPaginationParams} from '@/globals/utils/http';
import {StateHttpError} from '@/globals/types/http';
import {
  $customerDetail,
  $customerDetailError,
  $isCustomerDetailFetching,
  $customerList,
  $customerListCount,
} from '@/modules/customers/selectors/customer';
import * as actions from '@/modules/customers/actions/customer.detail';

interface CustomerListType {
  appName: string | null;
  channel: 'cafe24';
  page?: number;
  limit?: number;
  isDesc?: boolean;
  term?: string;
  ordering?: string;
  assignedTo?: string;
  assigning?: string;
  notAssigning?: string;
}

interface CustomerListReturnType {
  customers: types.Customer[];
  count: number;
}

interface CustomerDetailType {
  appName: string | null;
  uid: string;
}

interface CustomerDetailReturnType {
  customer: types.Customer | null;
  error: null | StateHttpError;
  isFetching: null | boolean;
}

export const useCustomerList = (params: CustomerListType): CustomerListReturnType => {
  const {appName, channel, page, limit, isDesc, term, ordering, assignedTo, assigning, notAssigning} = params;
  const dispatch = useDispatch();
  const customers = useSelector($customerList);
  const count = useSelector($customerListCount);

  useEffect(() => {
    if (!appName) {
      return;
    }
    const _params = {
      ...params,
      appName,
      isDesc: undefined,
      _ordering: ordering ? `${isDesc ? '-' : ''}${ordering}` : undefined,
    };
    dispatch(listActions.action.request(buildPaginationParams(_params)));
  }, [appName, channel, ordering, page, limit, isDesc, term, assignedTo, assigning, notAssigning]);

  return {customers, count};
};

export const useCustomerDetail = (params: CustomerDetailType): CustomerDetailReturnType => {
  const {appName, uid} = params;
  const dispatch = useDispatch();
  const customer = useSelector($customerDetail);
  const error = useSelector($customerDetailError);
  const isFetching = useSelector($isCustomerDetailFetching);

  useEffect(() => {
    if (!appName) { return; }

    dispatch(actions.action.request({
      appName,
      uid,
    }));
  }, [appName, uid]);

  return {
    customer,
    error,
    isFetching,
  };
};
