import dayjs from 'dayjs';

export const format = (value: string | Date | null | undefined, format = 'YYYY-MM-DD / HH:mm:ss') => {
  if (!value) {
    return null;
  }
  return dayjs(value).format(format);
};

export const isFuture = (base: Date, target: Date) => {
  return !dayjs(base).isSame(target, 'date') && base < target;
};
