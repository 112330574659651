import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {DatePicker as AntdDatePicker} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

import * as styles from '@/styles/globals';
import * as datetime from '@/globals/utils/datetime';

interface PropType {
  onChangeDate: (date: any) => void,
  onChangeVisible?: (status: boolean) => void,
  isVisible: boolean,
}

const Indicator: React.FC<PropType> = ({
  onChangeDate = (date: any) => {},
  onChangeVisible = (status: boolean) => {},
  ...props
                                       }) => {
  const [date, setDate] = useState<Date>(new Date());
  const [isVisible, setIsVisible] = useState(false);

  const isDisabledDate = (date: any) => {
    return datetime.isFuture(new Date(), date.toDate());
  };

  const onChangeDayByNav = (day: number) => {
    const _date = dayjs(date).add(day, 'd');
    setDate(_date.toDate());
    onChangeDate(_date);
  };

  const onToggle = () => {
    onChangeVisible(!isVisible);
    setIsVisible(!isVisible);
  };

  return (
    <Container>
      <IndicatorWrapper>
        <IndicatorLeftOutlinedButton onClick={() => onChangeDayByNav(-1)} />
        <div onClick={onToggle}>
          {datetime.format(date, 'YYYY년 M월 DD일')}
        </div>
        <IndicatorRightOutlinedButton onClick={() => onChangeDayByNav(1)} />
      </IndicatorWrapper>

      <DatePicker
        disabledDate={isDisabledDate}
        placeholder=""
        open={isVisible && props.isVisible}
        onChange={onChangeDate} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const IndicatorWrapper = styled.div`
  ${styles.text.header22}
  color: ${styles.color.gray10};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  width: 100%;
`;

const indicatorButtonStyle = css`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 9px;
  font-size: 10px;
`;

const IndicatorLeftOutlinedButton = styled(LeftOutlined)`${indicatorButtonStyle}`;
const IndicatorRightOutlinedButton = styled(RightOutlined)`${indicatorButtonStyle}`;

const DatePicker = styled(AntdDatePicker)``;

export default Indicator;

