import {createReducer} from 'typesafe-actions';
import {produce} from 'immer';
import _ from 'lodash';

import {HttpPaginationResponse} from "~globals/types/http";

import * as actions from '../actions/customerroutine.list';
import * as customerRoutineActionDeleteActions from '../actions/customerroutineaction.delete';
import * as types from '../types/routine';
import {CustomerRoutineListState} from '../states/routine';

type ActionType = actions.ActionType | customerRoutineActionDeleteActions.ActionType;

export default createReducer<types.CustomerRoutineListState, ActionType>(CustomerRoutineListState)
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const data = (payload as HttpPaginationResponse<types.CustomerRoutine>).data;
      draft.count = data.count;
      draft.items = data.results;
    });
  })
  .handleAction(customerRoutineActionDeleteActions.action.success, (state, action: customerRoutineActionDeleteActions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const {routine, action} = payload as types.PayloadDeleteSuccess;
      const index1 = draft.items.findIndex((o) => o.routine.slug === routine);
      if (index1 === -1) {return;}

      const index2 = draft.items[index1].items.findIndex((o) => o.action.slug === action);
      if (index2 === -1) {return;}
      draft.items[index1].items = _.without(
        draft.items[index1].items,
        draft.items[index1].items[index2],
      );
    });
  });
