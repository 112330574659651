import { getContext } from 'redux-saga/effects';

import * as actions from '../actions/route';

export function* moveToPage$(action: ReturnType<typeof actions.moveToPage>) {
  const {payload} = action;
  if (!payload) return;
  const history = yield getContext('history');
  history.push(payload);
}
