import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError, HttpResponse} from "~globals/types/http";

import * as actions from '../actions/routine.create';
import * as types from '../types/routine';
import {RoutineCreateState} from '../states/routine';

export default createReducer<types.RoutineCreateState, actions.ActionType>(RoutineCreateState)
  .handleAction(actions.minActions.action.request, (state) => {
    return produce(state, draft => {
      draft.error = null;
      draft.isCreating = true;
      draft.item = null;
    });
  })
  .handleAction(actions.minActions.action.success, (state, action: actions.MinActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      draft.error = null;
      draft.isCreating = false;
      draft.item = (payload as HttpResponse<types.Routine>).data;
    });
  })
  .handleAction(actions.minActions.action.failure, (state, action: actions.MinActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const res = (payload as HttpError<any>).response;
      draft.item = null;
      draft.isCreating = false;
      if (!res) {
        draft.error = {status: -1, data: payload};
      } else {
        draft.error = {status: res.status, data: res.data};
      }
    });
  })
  .handleAction(actions.resetCreateStateAction, (state) => {
    return produce(state, draft => {
      draft.item = null;
      draft.isCreating = false;
      draft.error = null;
    });
  });
