import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import {Location as LocationType} from 'history';
import qs from 'query-string';

import {
  Form as AntdForm,
  Input,
  Button,
  Row,
  Col,
  Typography,
} from 'antd';
import {
  UserOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import * as styles from '@/styles/globals';
import globalSettings from '~globals/settings';
import {getStorageData} from '~globals/utils/storage.local';
import {AUTH_TOKEN_STORAGE_KEY} from '~globals/utils/storage.keys';

import * as actions from '~modules/authentications/actions/login';

const getNextPage = (location: LocationType, defaultNext = globalSettings.routes.defaultPathAfterAuth): string => {
  const params = qs.parse(decodeURIComponent(location.search));
  return !params.next
    ? defaultNext
    : _.isArray(params.next) && params.next.length
      ? _.last(params.next) as string
      : params.next as string;
}

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (getStorageData(AUTH_TOKEN_STORAGE_KEY)) {
      navigate(getNextPage(location), {replace: true});
      return;
    }
    setIsVisible(true);
  }, [location, navigate]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);

  const onSubmit = () => {
    if (isDisableSubmit) { return; }
    setIsDisableSubmit(true);
    dispatch(actions.action.request({
      username,
      password,
    }));
    setIsDisableSubmit(false);
    return false;
  };

  return (
    <>
      {isVisible && (
        <Container>
          <FormContainer>
            <Form onFinish={onSubmit}>
              <Typography.Title className="title" level={2}>상담사 로그인</Typography.Title>

              <Form.Item
                className="item"
                name="email" rules={[
                  { required: true, message: '필수 항목' },
                ]}
              >
                <Input
                  prefix={<UsernameIcon />}
                  placeholder="ID"
                  value={username}
                  className="input"
                  onChange={(e) => setUsername(e.target.value)} />
              </Form.Item>
              <Form.Item
                className="item"
                name="password" rules={[
                { required: true, message: '필수 항목' },
              ]}
              >
                <Input.Password
                  prefix={<PasswordIcon />}
                  placeholder="Password"
                  value={password}
                  className="input"
                  onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>

              <Form.Item>
                <ButtonSubmit disabled={isDisableSubmit} type="primary" htmlType="submit">Log in</ButtonSubmit>
              </Form.Item>
            </Form>
          </FormContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled(Row)`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled(Col)`
  background-color: ${styles.color.gray4};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 960px;
  height: 640px;
`;

const Form = styled(AntdForm)`
  background-color: ${styles.color.white};
  width: 330px;
  padding: 24px;
 
  .title {
    ${styles.text.header20}
    color: ${styles.color.gray9};
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .item {
    margin: 24px 0;
  }
  .input {
    background-color: ${styles.color.white};
    padding: 10px 11px;
  }
`;

const UsernameIcon = styled(UserOutlined)`
  color: ${styles.color.primaryBlue};
`;

const PasswordIcon = styled(UnlockOutlined)`
  color: ${styles.color.primaryBlue};
`;

const ButtonSubmit = styled(Button)`
  ${styles.text.header16}
  background-color: ${styles.color.primaryBlue};
  color: ${styles.color.white};
  width: 100%;
  height: auto;
  padding: 8px 16px;
  border-radius: 2px;
`;

export default Login;
