import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';

import * as customerTypes from '@/modules/customers/types';
import * as actions from '@/modules/customers/actions/diary.detail';
import {$diaryDetail, $isDiaryDetailFetching} from '@/modules/customers/selectors/diary';

interface DiaryByDateType {
  date: Date;
  appName: string;
  customer: customerTypes.Customer;
}

const dateFormat = 'YYYY-MM-DD';

export const useDiaryByDate = ({date, appName, customer}: DiaryByDateType) => {
  const dispatch = useDispatch();
  const diary = useSelector($diaryDetail);
  const isLoading = useSelector($isDiaryDetailFetching);

  useEffect(() => {
    const target = dayjs(date);
    const today = new Date();
    if (!target.isSame(today, 'date') && target.toDate() > today) {
      return;
    }
    dispatch(actions.byDate.action.request({
      appName,
      customerUid: customer.uid,
      date: target.format(dateFormat),
    }));
  }, [date, customer, appName]);

  return {diary, isLoading};
};
