
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const setStorageData = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
};

export const getStorageData = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : null;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const delStorageData = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
    return null;
  }
};
