import {createReducer} from 'typesafe-actions';

import * as actions from '../actions/login';

export type State = Record<any, any>;

export const initialState: State = {};

export default createReducer<State, actions.ActionType>(initialState)
  .handleAction(actions.action.request, (state) => {
    return state;
  })
  .handleAction(actions.action.failure, (state) => {
    return state;
  })
  .handleAction(actions.action.success, (state) => {
    return state;
  });
