import React from 'react';
import {Form, Input} from 'antd';
import styled from 'styled-components';

import {SearchButton} from '@/components/common/buttons';

interface PropType {
  onSearch: () => void;
  onChange: (value: string) => void;
  onReset: () => void,
}

const TableMenu: React.FC<PropType> = ({
  onSearch,
  onChange,
  onReset,
  ...props
}) => {
  return (
    <TableMenuContainer>
      <Form
        onFinish={onSearch}
        className="searchForm">
        <Input
          className="inputSearchTerm"
          placeholder="루틴이나 액션 이름으로 검색해보세요."
          allowClear
          size="large"
          onChange={(e) => {
            if (!e.target.value?.length) {
              onReset();
            } else if (e.target.value?.length > 1) {
              onChange(e.target.value);
            }
          }}
        />
        <SearchButton htmlType="submit" />
      </Form>

      {props.children}
    </TableMenuContainer>

  );
};

const TableMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  .searchForm {
    display: flex;
    justify-content: flex-start;
  }
  .inputSearchTerm {
    width: 410px;
  }
`;

export default TableMenu;
