import axios from 'axios';
import {Cafe24CustomerListParam} from '~modules/authentications/types/cafe24';

const prefix = '/cafe24';

export async function fetchMalls(userOrganization: string | null) {
  return axios.get(`${prefix}/malls/`);
}

export async function checkAppAuthorization(clientId: string) {
  return axios.post(`${prefix}/applications/${clientId}/check-authorization/`);
}

export async function fetchAuthorizationUri(clientId: string) {
  return axios.post(`${prefix}/applications/${clientId}/oauth2-uri/`);
}

export async function fetchCustomers(params: Cafe24CustomerListParam) {
  return axios.get(`${prefix}/customers/`, {params});
}
