import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpPaginationResponse} from "~globals/types/http";

import * as actions from '../actions/customer.list';
import * as types from '../types/customer';
import {CustomerListState} from '../states/customer';

export default createReducer<types.CustomerListState, actions.ActionType>(CustomerListState)
  .handleAction(actions.action.success, (state, action: actions.CustomerListActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const data = (payload as HttpPaginationResponse<types.Customer>).data;
      draft.count = data.count;
      draft.customers = data.results;
    });
  })
  .handleAction(
    actions.updateCustomerDetail,
    (state, action: actions.UpdateCustomerDetailActionType) => {
      const {payload} = action;
      const data = (payload as types.Customer);
      return produce(state, draft => {
        const index = draft.customers.findIndex((o) => o.uid === data.uid);
        if (index !== -1) {
          draft.customers[index] = data;
        }
      });
    });
