import React from 'react';
import {useDispatch} from 'react-redux';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import {BaseButton} from '@/components/common/buttons';
import * as logoutActions from '@/modules/authentications/actions/logout';

type PropType = {
  onMobileNavOpen?: any;
  styles?: {[key: string]: string | number};
};

const TopBar: React.FC<PropType> = ({ ...props }) => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logoutActions.action.request(''));
  };

  return (
    <Container>
      <BaseButton onClick={onLogout} icon={<LogoutOutlined />}>로그아웃</BaseButton>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${styles.color.white};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  padding: 0 12px;
`;

export default TopBar;
