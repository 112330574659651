import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Space,
  Typography,
  Input,
  Select,
  Button,
  Modal,
  Radio,
  message,
} from 'antd';

import * as types from '~modules/skinschool/types';

type RoutinePriorityType = 'primary' | 'secondary';

type RoutineTypes = types.BaseRoutine | types.Routine | types.SimpleRoutine;

type PropType = {
  routinePriority: RoutinePriorityType;
  routines: Array<RoutineTypes>,
  onCancel: () => void;
  onSubmit: (payload: types.AddActionToRoutinePayload) => void;
  onSubmitCreateRoutine: (value: string, checkKind: types.CheckKindType) => void;
  isLoading: boolean;
  initialRoutine: null | RoutineTypes;
  visible: boolean;
};

const AddActionToRoutine: React.FC<PropType> = ({
  routinePriority,
  routines,
  onCancel,
  onSubmit,
  onSubmitCreateRoutine,
  isLoading,
  initialRoutine,
  visible,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [payload, setPayload] = useState<types.AddActionToRoutinePayload>({
    appName: '',
    routine: selectRoutineHeadValue,
    name: '',
    description: '',
    slug: null,
    value: null,
  });
  const [selectedRoutine, setSelectedRoutine] = useState<string>(selectRoutineHeadValue);
  const [checkKind, setCheckKind] = useState('');

  useEffect(() => {
    if (initialRoutine) {
      setSelectedRoutine(initialRoutine.slug);
      makePayload('routine', initialRoutine.slug);
    } else {
      setSelectedRoutine(selectRoutineHeadValue);
    }
  }, [initialRoutine]);

  useEffect(() => {
    setIsValid(!!payload.routine && !!payload.name);
  }, [payload]);


  const makePayload = (key: string, value: any) => {
    setPayload({
      ...payload,
      [key]: value,
    });
  };

  const getCheckKindByRoutineSlug = (slug: string) => {
    const routine = routines.find((o) => o.slug === slug);
    return routine ? routine.checkKind : '';
  };

  const Footer = () =>  (
    <ButtonContainer>
      <Button disabled={isLoading} onClick={onCancel}>취소</Button>
      <Button disabled={isLoading || !isValid} onClick={() => onSubmit(payload)}>
        {routineKindNames[routinePriority]}
        액션 추가
      </Button>
    </ButtonContainer>
  );

  return (
    <ModalContainer
      visible={visible}
      centered
      width={820}
      title={`${routineKindNames[routinePriority]} 액션 추가`}
      onCancel={onCancel}
      footer={<Footer />}>
      <Space direction="vertical">

        <RoutineControlContainer>
          <Space direction="vertical" className="routineControl">
            <Typography.Title level={4}>루틴/케어 선택</Typography.Title>

            <Select
              style={{width: '256px'}}
              defaultValue={selectedRoutine}
              value={selectedRoutine}
              disabled={isLoading}
              onChange={(value) => {
                const slug = !value ? '' : (value as string);
                makePayload('routine', slug);
                setSelectedRoutine(slug);
                setCheckKind(getCheckKindByRoutineSlug(slug));
              }}
            >
              <Select.Option disabled={true} value={selectRoutineHeadValue}>----------</Select.Option>
              {routines.map((o) => (
                <Select.Option key={o.slug} value={o.slug}>{o.name}</Select.Option>
              ))}
              {routinePriority !== 'primary' && <Select.Option value="">신규 케어 제목 추가</Select.Option>}
            </Select>
          </Space>

          {routinePriority !== 'primary' && (
            <Space direction="vertical">
              <Typography.Title level={4}>
                {routineKindNames[routinePriority]}
                제목 추가
              </Typography.Title>

              <Input.Search
                disabled={isLoading || payload.routine !== ''}
                enterButton="추가"
                onSearch={(value: string) => {
                  if (!value || !checkKind) {
                    message.error('이름과 유형은 필수 항목입니다.');
                    return;
                  }
                  onSubmitCreateRoutine(value, checkKind as types.CheckKindType);
                }}
              />

              <Typography.Text>새롭게 추가할 케어의 제목을 입력하세요.</Typography.Text>
            </Space>
          )}
        </RoutineControlContainer>

        <Space direction="vertical">
          <Typography.Title level={5}>케어 유형 선택</Typography.Title>

          <Radio.Group
            value={checkKind}
            disabled={selectedRoutine !== ''}
            onChange={(e) => setCheckKind(e.target.value)}
          >
            <Radio value="multiple">복수 액션 체크 (다중응답)</Radio>
            <Radio value="singular">단일 액션 체크 (단일응답)</Radio>
            <Radio value="yes_or_no">예, 아니오로 대답</Radio>
          </Radio.Group>
        </Space>

        <AddActionContainer direction="vertical">
          <Typography.Title level={4}>액션 추가</Typography.Title>

          <Typography.Text>액션 제목</Typography.Text>
          <Input
            disabled={isLoading}
            placeholder="제목을 입력하세요."
            className="inputActionName"
            onChange={(e) => makePayload('name', e.target.value)}
          />

          <Typography.Text>액션 설명</Typography.Text>
          <Input
            disabled={isLoading}
            placeholder="설명을 입력하세요. 생략 가능."
            className="inputActionDescription"
            onChange={(e) => makePayload('description', e.target.value)}
          />
        </AddActionContainer>

      </Space>
    </ModalContainer>
  );
};

const selectRoutineHeadValue = '!!invalid!!';

const routineKindNames: {[key in RoutinePriorityType]: string} = {
  primary: '메인 루틴',
  secondary: '커스텀 케어 루틴',
};

const ModalContainer = styled(Modal)``;

const RoutineControlContainer = styled(Space)`
  display: flex;
  align-items: flex-start;

  .routineControl {
    margin-right: 30px;
  }
`;

const AddActionContainer = styled(Space)`

  .inputActionName {
    padding: 8px 12px;
    width: 256px;
    margin: 0;
  }

  .inputActionDescription {
    padding: 8px 12px;
    width: 720px;
  }

`;

const ButtonContainer = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 0 8px;
`;

export default AddActionToRoutine;
