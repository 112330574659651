import {all, takeLatest} from "redux-saga/effects";

import * as customerListActions from "../actions/customer.list";
import * as customerDetailActions from "../actions/customer.detail";
import * as diaryDetailActions from "../actions/diary.detail";
import * as customerSyncActions from '../actions/customer.sync';
import * as staffCustomerAssignmentCreate from '../actions/staff.customer.assignments.set';

import {
  fetchCustomers$,
  fetchCustomerDetail$,
  syncCustomerCafe24$,
  setStaffCustomerAssignments$,
} from "./customer";
import {
  fetchDiaryDetailByUid$,
  fetchDiaryDetailByDate$,
} from "./diary";

export default function* () {
  yield all([
    takeLatest(customerListActions.REQUEST, fetchCustomers$),
    takeLatest(customerDetailActions.REQUEST, fetchCustomerDetail$),
    takeLatest(diaryDetailActions.byUid.REQUEST, fetchDiaryDetailByUid$),
    takeLatest(diaryDetailActions.byDate.REQUEST, fetchDiaryDetailByDate$),
    takeLatest(customerSyncActions.REQUEST, syncCustomerCafe24$),
    takeLatest(staffCustomerAssignmentCreate.REQUEST, setStaffCustomerAssignments$),
  ]);
}
