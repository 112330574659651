import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpPaginationResponse} from "~globals/types/http";

import * as actions from '../actions/routine.list';
import * as types from '../types/routine';
import {RoutineListState} from '../states/routine';

export default createReducer<types.RoutineListState, actions.ActionType>(RoutineListState)
  .handleAction(actions.action.request, (state, action: actions.ActionType) => {
    return produce(state, draft => {
      draft.isFetching = true;
    });
  })
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      const data = (payload as HttpPaginationResponse<types.Routine>).data;
      draft.count = data.count;
      draft.items = data.results;
      draft.isFetching = false;
    });
  })
  .handleAction(actions.action.failure, (state) => {
    return produce(state, draft => {
      draft.isFetching = false;
    });
  });
