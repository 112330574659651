import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Space,
  Typography,
  Input,
  Select,
  Button,
  Form,
  DatePicker,
  message,
  Cascader,
} from 'antd';
import dayjs from 'dayjs';

import * as styles from '@/styles/globals';
import * as types from '~modules/skinschool/types/routine';

export type OnChangePayload = {
  openedAt: null | Date;
  closedAt: null | Date;
  routine: null | string;
  action: null | string;
  isDateValid: boolean;
};

type PropType = {
  routines: types.Routine[];
  onChange: (payload: OnChangePayload) => void;
};

const AddRoutineForm: React.FC<PropType> = ({routines, onChange}) => {
  const [isValid, setIsValid] = useState(true);
  const [dates, setDates] = useState<Array<null | Date>>([null, null]);
  const [selectedAction, setSelectedAction] = useState<null | string[]>(null);
  const items = routines.map((o) => {
    return {
      value: o.slug,
      label: o.name,
      children: o.items.map((action) => {
        return {
          value: action.slug,
          label: action.name,
        };
      }),
    }
  });

  useEffect(() => {
    const [routine, action] = selectedAction ? selectedAction : [];
    onChange({
      openedAt: dates[0],
      closedAt: dates[1],
      routine: routine || null,
      action: action || null,
      isDateValid: (!dates[0] && !dates[1]) || isValid,
    });
  }, [dates, selectedAction]);

  const onPickingDate = (openDate: null | Date, closeDate: null | Date) => {
    const today = dayjs(new Date());
    if (openDate && !today.isSame(openDate, 'date') && openDate < today.toDate()) {
      message.error('시작일자는 과거로 지정할 수 없습니다.');
      setIsValid(false);
      return;
    }
    if (closeDate && !today.isSame(closeDate, 'date') && closeDate < today.toDate()) {
      message.error('종료일자는 과거로 지정할 수 없습니다.');
      setIsValid(false);
      return;
    }
    if (openDate && closeDate && openDate > closeDate) {
      message.error('종료일자는 시작날짜 이후여야 합니다.');
      setIsValid(false);
      return;
    }
    setIsValid(true);
    setDates([openDate, closeDate]);
  };

  return (
    <Container>
      <Section>
        <Typography.Title className="title" level={3}>날짜 설정</Typography.Title>
        <Typography.Text className="description">시작과 종료 날짜를 설정해주세요</Typography.Text>
      </Section>

      <DateContainer>
        <Space direction="vertical" className="dateItemContainer">
          <Typography.Title className="dateLabel" level={5}>시작 날짜</Typography.Title>
          <DatePicker
            onChange={(value: any) => {
              onPickingDate(value ? value.toDate() : value, dates[1]);
            }}
          />
        </Space>
        <Space direction="vertical" className="dateItemContainer">
          <Typography.Title className="dateLabel" level={5}>종료 날짜</Typography.Title>
          <DatePicker
            onChange={(value: any) => {
              onPickingDate(dates[0], value ? value.toDate() : value);
            }}
          />
        </Space>
      </DateContainer>

      <Section>
        <Typography.Title className="title" level={3}>케어, 액션 선택</Typography.Title>
        <Typography.Text className="description">추가할 케어와 액션을 선택하세요.</Typography.Text>
      </Section>

      <Space>
        <Cascader
          style={{width: '772px'}}
          options={items}
          onChange={(v) => {
            setSelectedAction(v as string[]);
          }}
          placeholder="케어, 액션 선택"
        />
      </Space>

    </Container>
  );
};

const Container = styled.div``;

const Section = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;

  .title {
    ${styles.text.header18}
    color: ${styles.color.gray9};
    margin: 0 12px 0 0 !important;
  }
  .description {
    ${styles.text.body14}
    color: ${styles.color.gray7};
  }
`;

const DateContainer = styled(Space)`
  margin-bottom: 24px;
  
  .dateItemContainer {
    margin-right: 14px;
    .dateLabel {
      margin: 0 !important;
    }
  }
`;

export default AddRoutineForm;
