
import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {Location as LocationType} from 'history';

import {getStorageData} from '~globals/utils/storage.local';
import {AUTH_TOKEN_STORAGE_KEY} from '~globals/utils/storage.keys';
import globalSettings from '~globals/settings';
import _ from 'lodash';

export const setupHttpClient = () => {
  setupTokenAuthClient(getStorageData(AUTH_TOKEN_STORAGE_KEY));

  axios.defaults.timeout = 10000;
  axios.defaults.baseURL = globalSettings.apiserver.host;
  axios.interceptors.request.use(async (config) => {
    if (config.data) {
      config.data = snakecaseKeys(config.data, {deep: true});
    }
    return config;
  });
  axios.interceptors.response.use((config) => {
    if (config.data) {
      config.data = camelcaseKeys(config.data, {deep: true});
    }
    return config;
  });
};

export const setupTokenAuthClient = (token: string | null) => {
  if (!token) { return; }
  axios.defaults.headers['Authorization'] = `Token ${token}`;
};

export const buildNextUrl = (next: string | undefined, location: LocationType) => {
  let _next = '';
  if (next) {
    _next = next;
  } else if (location.pathname) {
    _next = `${location.pathname}${location.search}`;
  } else {
    _next = globalSettings.routes.defaultPathAfterAuth;
  }
  return encodeURIComponent(_next);
};

export const parseAppName = (value: string) => {
  const parts = value.split('service_');

  if (parts.length !== 2 || parts[0] !== '') {
    throw new Error('이용자 app name 이 유효하지 않습니다.');
  }
  return parts[1];
};

const defaultPageLimit = 12;

const defaultParam = {
  page: 1,
  limit: defaultPageLimit,
};

export const buildPaginationParams = <T extends IPaginationParam>(params: T) => {
  return _.assign(defaultParam, params);
};

export interface IPaginationParam {
  page?: number;
  limit?: number;
}
