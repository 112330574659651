import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
  }, []);
  return (
    <Layout>
    </Layout>
  );
};

export default Home;
