import React, {useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Table,
  Typography,
} from 'antd';
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import * as datetime from '~globals/utils/datetime';
import * as types from '~modules/skinschool/types';
import {$routineList} from '~modules/skinschool/selectors/routines';
import * as customerRoutineActionAddActions from '~modules/skinschool/actions/customerroutineaction.add';

import * as styles from '@/styles/globals';
import {AddButton} from '~components/common/buttons/Icon';
import AddCustomerRoutineAction, {
  SubmitPayloadType as SubmitAddingActionPayloadType,
} from '~components/customer/AddCustomerRoutineAction';

type PropType = {
  appName: string;
  customer: string;
  routine: types.CustomerRoutine;
  onDelete: (routineAction: types.RoutineAction) => void;
};

const CustomerRoutineSection: React.FC<PropType> = (props) => {
  const dispatch = useDispatch();

  const {appName, customer} = props;

  const {routine, onDelete} = props;
  const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
  const [actions, setActions] = useState<types.SimpleRoutineAction[]>([]);

  const routineList = useSelector($routineList);

  const onOpenCustomerRoutineAction = () => {
    const current = routineList.find((o) => o.slug === routine.routine.slug);
    if (!current) {return;}
    setActions(current.items);
    setIsVisibleAddModal(true);
  };

  const onSubmitAddingAction = (payload: SubmitAddingActionPayloadType) => {
    dispatch(customerRoutineActionAddActions.action.request({
      appName,
      customer,
      routine: payload.routine.slug,
      action: payload.action.slug,
      openedAt: payload.dates[0] ? datetime.format(payload.dates[0], defaultDatetimeFormat) :  null,
      closedAt: payload.dates[1] ? datetime.format(payload.dates[1], defaultDatetimeFormat) :  null,
    }));
    setIsVisibleAddModal(false);
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Typography.Title className="title" level={4}>{routine.routine.name}</Typography.Title>
          <Typography.Text className="description">{routine.routine.name} 액션목록</Typography.Text>
        </TitleContainer>
        <ButtonAddAction
          icon={<AddButtonIcon />}
          onClick={onOpenCustomerRoutineAction}>액션 추가</ButtonAddAction>
      </Header>

      <Table
        dataSource={routine.items}
        rowKey={(row) => row.action.slug}
      >
        <Table.Column title="번호" render={(_, __, index) => <div>{index+1}</div>} />

        <Table.Column
          title="루틴 제목"
          render={() => <div>{routine.routine.name}</div>}
        />

        <Table.Column title="액션 제목" dataIndex={['action', 'name']} />

        <Table.Column title="액션 설명" dataIndex={['action', 'description']} />

        <Table.Column
          title="시작 날짜"
          dataIndex={['openedAt']}
          render={(value) => <div>{value? datetime.format(value, defaultDateFormat) : '-'}</div>}
        />

        <Table.Column
          title="종료 날짜"
          dataIndex={['closedAt']}
          render={(value) => <div>{value? datetime.format(value, defaultDateFormat) : '-'}</div>}
        />

        <Table.Column
          title="삭제"
          render={(row) => <Button icon={<DeleteOutlined />} onClick={() => onDelete(row.action)} />}
        />
      </Table>

      <AddCustomerRoutineAction
        title="액션 추가"
        width={820}
        visible={isVisibleAddModal}
        customerRoutine={routine}
        actions={actions}
        onSubmit={onSubmitAddingAction}
        onCancel={() => setIsVisibleAddModal(false)}
      />
    </Container>
  );
};

const defaultDatetimeFormat = 'YYYY-MM-DDT00:00:00+09:00';

const defaultDateFormat = 'YYYY-MM-DD';

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    ${styles.text.header20}
    color: ${styles.color.gray9};
    margin: 0 12px 0 0 !important;
  }
  .description {
    ${styles.text.body14}
    color: ${styles.color.gray7};
  }
`;

const ButtonAddAction = styled(AddButton)`
  ${styles.text.body14}
  color: ${styles.color.primaryBlue};
  background-color: ${styles.color.white};
  border-color: ${styles.color.primaryBlue};
  border-width: 1px;
  border-style: solid;
  padding: 4px 12px;
`;

const AddButtonIcon = styled(PlusCircleOutlined)`
  background-color: ${styles.color.green};
  color: ${styles.color.primaryBlue};
  ${styles.text.body16}
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

export default CustomerRoutineSection;
