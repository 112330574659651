import {createReducer} from 'typesafe-actions';
import {produce} from 'immer';

import {BreadCrumbRouteType} from '../types/route';
import * as routeActions from '../actions/route';

export type State = {
  breadCrumbsRoute: BreadCrumbRouteType[];
};

export const initialState: State = {
  breadCrumbsRoute: [],
}

export default createReducer<State, routeActions.ActionType>(initialState)
  .handleAction(
    routeActions.updateBreadCrumbsRoute,
    (state, action: routeActions.UpdateBreadCrumbsRouteType) => {
      const {payload} = action;
      return produce(state, draft => {
        draft.breadCrumbsRoute = payload;
      });
    }
  );
