import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";
import {Cafe24Mall} from '~modules/authentications/types/cafe24';

export const ENTITY_NAME = 'authentications/CAFE24_MALL_LIST';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<string | null, HttpResponse<Cafe24Mall[]>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
