import {createReducer} from 'typesafe-actions';
import {produce} from 'immer';

import {HttpPaginationResponse} from "~globals/types/http";

import * as actions from '../actions/cafe24.customer.list';
import {Cafe24Customer, Cafe24CustomerState} from '~modules/authentications/types/cafe24';

export const initialState: Cafe24CustomerState = {
  customers: [],
};

export default createReducer<Cafe24CustomerState, actions.ActionType>(initialState)
  .handleAction(actions.action.request, (state, action: actions.ActionType) => {
    return state;
  })
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      draft.customers = (payload as HttpPaginationResponse<Cafe24Customer>).data.results;
    });
  });
