import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpResponse} from "~globals/types/http";

import * as actions from '../actions/cafe24.mall.list';
import {Cafe24Mall, Cafe24MallState} from '~modules/authentications/types/cafe24';

export const initialState: Cafe24MallState = {
  malls: [],
};

export default createReducer<Cafe24MallState, actions.ActionType>(initialState)
  .handleAction(actions.action.failure, (state, action: actions.ActionType) => {
    return state;
  })
  .handleAction(actions.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      draft.malls = (payload as HttpResponse<Cafe24Mall[]>).data;
    });
  });
