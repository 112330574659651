import {all, takeLatest} from "redux-saga/effects";

import * as loginActions from "../actions/login";
import {login$} from "./login";

import * as logoutActions from "../actions/logout";
import {logout$} from "./logout";

import * as userDetailActions from "../actions/user.detail";
import {fetchUserDetail$} from "./user";

import * as cafe24MallListActions from "../actions/cafe24.mall.list";
import * as cafe24AuthActions from "../actions/cafe24.authorization";
import * as cafe24CustomerListActions from "../actions/cafe24.customer.list";
import {
  fetchMalls$,
  checkAppAuthorization$,
  fetchAuthorizationUri$,
  fetchCustomers$ as cafe24FetchCustomers$,
} from "./cafe24";

export default function* () {
  yield all([
    takeLatest(loginActions.REQUEST, login$),
    takeLatest(logoutActions.REQUEST, logout$),
    takeLatest(userDetailActions.REQUEST, fetchUserDetail$),
    takeLatest(cafe24MallListActions.REQUEST, fetchMalls$),
    takeLatest(cafe24AuthActions.checkAuthorization.REQUEST, checkAppAuthorization$),
    takeLatest(cafe24AuthActions.authorizationUri.REQUEST, fetchAuthorizationUri$),
    takeLatest(cafe24CustomerListActions.REQUEST, cafe24FetchCustomers$),
  ]);
}
