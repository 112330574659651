import { RootState } from '@/store';

const $rootAuth = (state: RootState) => state.customer;

export const $customerList = (state: RootState) => $rootAuth(state).list.customers;
export const $customerListCount = (state: RootState) => $rootAuth(state).list.count;

export const $customerDetail = (state: RootState) => $rootAuth(state).detail.item;
export const $customerDetailError = (state: RootState) => $rootAuth(state).detail.detailError;
export const $isCustomerDetailFetching = (state: RootState) => $rootAuth(state).detail.isDetailFetching;
