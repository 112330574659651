import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  Col,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';

export interface OnChangeParamType {
  ordering: string;
  isDesc: boolean;
  term: string;
}

interface PropType {
  onChange: (params: OnChangeParamType) => void;
  isLoading?: boolean;
}

const QuerySection: React.FC<PropType> = (props) => {
  const {onChange, isLoading} = props;

  const [ordering, setOrdering] = useState('order_date');
  const [isDesc, setIsDesc] = useState(true);
  const [term, setTerm] = useState('');

  useEffect(() => {
    onChange({ordering, isDesc, term});
  }, [ordering, isDesc, term]);

  return (
    <TableMenu>
      <Col span={14}>
        <Space>
          <Select className="orderingContainer"
                  defaultValue={ordering}
                  onChange={setOrdering}
                  loading={!!isLoading}>
            <Select.Option value="order_date">마지막 결제일</Select.Option>
            <Select.Option value="customerapp_date">최근 접속 일시</Select.Option>
            <Select.Option value="progress_points">진척도 (어제)</Select.Option>
          </Select>
        </Space>

        <Space className="sortContainer">
          <Radio.Group
            options={sortOptions}
            onChange={(e) => setIsDesc(e.target.value)}
            value={isDesc}
            optionType="button"
            buttonStyle="solid"
          />
        </Space>

      </Col>

      <Col span={10} className="searchTermContainer">
        <Space>
          <Input.Search
            placeholder="이름, 아이디로 검색"
            allowClear
            onSearch={setTerm}
            className="inputSearchTerm"
          />
        </Space>

        {!!props.children && props.children}
      </Col>
    </TableMenu>
  );
};

const TableMenu = styled(Row)`
  width: 100%;
  
  .orderingContainer {
    width: 256px;
  }
  .sortContainer {
    margin-left: 14px;
  }

  .searchTermContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .inputSearchTerm {
      width: 256px;
    }
  }
`;

const sortOptions = [
  {
    label: '오름차순',
    value: false,
  },
  {
    label: '내림차순',
    value: true,
  },
];

export default QuerySection;
