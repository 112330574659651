import axios from 'axios';

import {parseAppName} from '~globals/utils/http';
import * as types from '../types/diary';

export async function fetchDiaryDetail(params: types.DiaryDetailByUidParam) {
  const {appName, uid} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/diaries/${uid}/`);
}

export async function fetchDiaryDetailByDate(params: types.DiaryDetailByDateParam) {
  const {appName, customerUid, date} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/diaries/@${date}/${customerUid}/`);
}
