import React from 'react';
import { RouteObject } from 'react-router';
import { Outlet, matchRoutes } from 'react-router-dom';
import _ from 'lodash';

import {BreadCrumbRouteType} from '@/globals/types/route';

import AuthRequired from "./containers/AuthRequired";
import MainLayout from './containers/layouts/MainLayout/MainLayout';
import CommonLayout from './containers/layouts/CommonLayout';

import Home from './containers/pages/Home';
import Login from './containers/pages/Login';
import Desk from './containers/pages/desk/Desk';
import CustomerList from './containers/pages/customers/CustomerList';
import CustomerDetail from './containers/pages/customers/CustomerDetail';
import RoutineActionList from '~containers/pages/routines/RoutineActionList/RoutineActionList';
import PageNotFound from './containers/pages/errors/PageNotFound';

export interface ExtendedRouteObject extends RouteObject {
  name?: string;
  children?: ExtendedRouteObject[];
}

export const getBreadCrumbs: (path: string) => BreadCrumbRouteType[] = (path) => {
  const matched = matchRoutes(routes, path) || [];
  return matched
    .filter((o: any) => !!o.route.name)
    .map((o: any) => {
      return {
        name: o.route.name,
        path: o.route.path,
      };
    })
    .reduce((acc, cur) => {
      acc.push({
        path: `${_.last(acc)?.path || ''}${cur.path}`,
        name: cur.name,
      });
      return acc;
    }, [] as BreadCrumbRouteType[]);
};

const routes: ExtendedRouteObject[] = [
  {
    path: '/desk',
    name: 'HOME',
    caseSensitive: false,
    element: <AuthRequired><MainLayout /></AuthRequired>,
    children: [
      {
        path: '',
        element: <Desk />,
        caseSensitive: false,
      },
      {
        path: '/customers',
        name: '고객목록',
        caseSensitive: false,
        element: <Outlet />,
        children: [
          {
            path: '/@assigned/:uid',
            name: '고객상세',
            caseSensitive: false,
            element: <CustomerDetail key="customerdetail-assigned" />,
          },
          {
            path: '/@all/:uid',
            name: '고객상세',
            caseSensitive: false,
            element: <CustomerDetail key="customerdetail-all" />,
          },
          {
            path: '/@all',
            caseSensitive: false,
            element: <CustomerList key="customerlist-all" type="all" />,
          },
          {
            path: '/@assigned',
            caseSensitive: false,
            element: <CustomerList key="customerlist-assigned" type="assigned" />,
          },
        ],
      },
      {
        path: '/routine-actions',
        name: '루틴 액션 관리',
        caseSensitive: false,
        element: <Outlet />,
        children: [
          {
            path: '/main',
            caseSensitive: false,
            element: <RoutineActionList key="routineactionlist-main" routinePriority="primary" />,
          },
          {
            path: '/sub',
            caseSensitive: false,
            element: <RoutineActionList key="routineactionlist-sub" routinePriority="secondary" />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    caseSensitive: false,
    element: <CommonLayout />,
    children: [
      {
        path: 'login',
        caseSensitive: false,
        element: <Login />,
      },
      {
        path: '404',
        caseSensitive: false,
        element: <PageNotFound />,
      },
      {
        path: '',
        caseSensitive: false,
        element: <Home />,
      },
      {
        path: '*',
        caseSensitive: false,
        element: <PageNotFound />,
      }
    ]
  },
];

export default routes;
