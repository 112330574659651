import React from 'react';
import styled from 'styled-components';
import {
  Button as AntdButton,
} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {
  SearchOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import * as styles from '@/styles/globals';

type IconPropType = {
  icon?: any;
};

type PropType = IconPropType & ButtonProps;

export const BaseButton: React.FC<PropType> = (props) => {
  const {icon, children, ...rest} = props;
  return (
    <Button icon={icon} {...rest}>{children}</Button>
  );
};

export const SearchButton: React.FC<PropType> = (props) => {
  const {icon, ...rest} = props;
  return (
    <BaseButton icon={icon || <SearchOutlined />} {...rest} />
  );
};

export const AddButton: React.FC<PropType> = (props) => {
  const {icon, ...rest} = props;
  return (
    <BaseButton icon={icon || <PlusCircleOutlined />} {...rest} />
  );
};

const Button = styled(AntdButton)`
  background-color: ${styles.color.primaryBlue};
  color: ${styles.color.white};
  padding: 8px 16px;
  width: auto;
  height: auto;
`;
