import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {
  Table as AntdTable,
  Pagination,
  notification,
} from 'antd';
import {useNavigate, useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  SyncOutlined,
} from '@ant-design/icons';
import { default as copyToClipboard } from 'copy-to-clipboard';
import useSWR from 'swr';

import * as styles from '@/styles/globals';
import {useUser} from '@/modules/authentications/hooks/auth';
import {action as customerSyncAction} from '@/modules/customers/actions/customer.sync';
import * as types from '@/modules/customers/types';
import * as datetime from '@/globals/utils/datetime';
import * as numberUtils from '@/globals/utils/number';
import PageBreadcrumb from '@/containers/PageBreadcrumb';
import {useCafe24Authentication} from '@/hooks/cafe24';
import Cafe24AuthorizationError from '@/containers/pages/errors/Cafe24Authorization';

import QuerySection, {OnChangeParamType} from './QuerySection';
import AssignToStaff from './AssignToStaff';
import axios from 'axios';
import qs from 'query-string';
import snakecaseKeys from 'snakecase-keys';
import {PaginationResponse} from '~globals/types/http';

type PropType = {
  type: 'all' | 'assigned',
};

const appName = 'service_skinschool';

const CustomerList: React.FC<PropType> = ({type}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageLimit);
  const [term, setTerm] = useState('');
  const [ordering, setOrdering] = useState('-order_date');
  const [isDesc, setIsDesc] = useState(true);

  const [user,] = useUser();
  const cafe24Authentication = useCafe24Authentication();
  const makeRowNumber = useCallback((index: number) => {
    return (page-1) * pageSize + index + 1;
  }, [page, pageSize]);

  const { data, isLoading, isError } = useCustomers({
    appName,
    channel: 'cafe24',
    page,
    limit: pageSize,
    term: term,
    ordering: `${isDesc ? '-' : ''}${ordering}`,
    assignedTo: type === 'assigned' ? (user?.username || '') : '',
  });

  const onChangeQuery = (params: OnChangeParamType) => {
    params.isDesc !== isDesc && setIsDesc(params.isDesc);
    params.ordering !== ordering && setOrdering(params.ordering);
    params.term !== term && setTerm(params.term);
  };

  if (cafe24Authentication.isRequired && cafe24Authentication.authUri) {
    return <Cafe24AuthorizationError authUri={cafe24Authentication.authUri} />;
  }

  const onCell = (record: types.Customer) => {
    return {
      onClick: (event: any) => copyUid(record, event),
    };
  };
  const onRow = (record: any) => {
    return {
      onClick: (event: any) => {
        // console.log(2222, record, event)
      },
      onDoubleClick: (event: any) => {
        navigate(`/${location.pathname}/${record.uid}`);
      },
    };
  };

  const onSyncCustomer = (uid: string) => {
    dispatch(customerSyncAction.request({
      mallProvider: 'cafe24',
      uid,
    }));
  }

  return (
    <Container>
      <PageBreadcrumb title={typeUi.pageTitle[type] || '고객목록'} subTitle="스킨스쿨 솔루션 구매 고객목록" />

      <MainContainer>

        <QuerySection onChange={onChangeQuery} isLoading={isLoading}>
          {(type === 'assigned' && !!user?.username) && (
            <AssignToStaffWrapper><AssignToStaff username={user.username} /></AssignToStaffWrapper>
          )}
        </QuerySection>

        <Table
          dataSource={data ? data.results : []}
          pagination={false}
          onRow={onRow}
          rowKey={(row: any) => row.uid}>
          <Table.Column title="번호" render={(_, __, index) => <div>{makeRowNumber(index)}</div>} />

          <Table.Column title="고객 이름" dataIndex={['extra', 'privacy', 'name']} />

          <Table.Column title="스킨스쿨 아이디" dataIndex={['username']} />

          <Table.Column title="고객 등급" dataIndex={['extra', 'group', 'groupName']} />

          <Table.Column
            title="마지막 결제일"
            dataIndex={['order', 'latest']}
            render={(value) => <div>{value?.order?.orderDate ? datetime.format(value?.order?.orderDate) : '-'}</div>}
          />

          <Table.Column
            title="최근 앱 접속시간"
            dataIndex={['customerApp', 'latestDate']}
            render={(value) => <div>{datetime.format(value) || '-'}</div>}
          />

          <Table.Column
            title="채널톡 아이디"
            dataIndex={['uid']}
            ellipsis
            onCell={onCell}
          />

          <Table.Column
            title="진척도"
            dataIndex={['diary']}
            render={(value) => <div>{`${getDiaryProgressLabel(value, true)}`}</div>}
          />

          <Table.Column
            title="어제 진척도"
            dataIndex={['yesterdayDiary']}
            render={(value) => <div>{`${getDiaryProgressLabel(value, false)}`}</div>}
          />

          <Table.Column
            title="동기화"
            dataIndex={['uid']}
            render={(value) => <div onClick={() => onSyncCustomer(value)}><SyncOutlined /></div>}
          />
        </Table>

        <Pagination
          pageSize={pageSize}
          total={data ? data.count : 0}
          onChange={(page, pageSize) => {
            setPage(page);
            (pageSize && pageSize > 0) && setPageSize(pageSize);
          }}
        />
      </MainContainer>
    </Container>
  );
};

const getDiaryProgressLabel = (value: types.Diary | null, isToday: boolean) => {
  if (!value) { return '-'; }
  if (isToday && !dayjs(new Date()).isSame(value.date, 'date')) { return '-'; }
  if (!value.progressPoints) { return 0; }
  const {progress, total} = value.progressPoints;
  const result = numberUtils.calcPercent(progress, total);
  return `${result}%`;
};

const copyUid = (record: types.Customer, event: any) => {
  event.stopPropagation();
  if (copyToClipboard(record.uid)) {
    notification.open({
      message: `${record.username} 고객의 채널톡 아이디를 클립보드에 복사했습니다.`,
    });
  }
};

const defaultPageLimit = 12;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  background-color: ${styles.color.white};
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  padding: 27px 24px 20px;
`;

const Table = styled(AntdTable)`
  margin: 12px 9px 20px 11px;
`;

const AssignToStaffWrapper = styled.div`
  margin-left: 10px;
`;

const typeUi = {
  pageTitle: {
    all: '전체 고객목록',
    assigned: '커스텀 고객목록',
  },
  showAssignButton: {
    all: false,
    assigned: true,
  },
};

const fetcher = async (url: string) => {
  const res = await axios.get(url);
  return res.data;
}

const useCustomers = (params: types.CustomerListParam) => {
  const query = `?${qs.stringify(snakecaseKeys(params))}`;
  const url = `/@skinschool/customers/${query}`;
  const { data, error } = useSWR<PaginationResponse<types.Customer>>(
    url,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    },
  );
  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default CustomerList;
