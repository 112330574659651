import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import {NavigateFunction} from 'react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';

import reducers, {rootSaga} from './modules';

export const history = createBrowserHistory();

type sagaContextType = {
  history: typeof history,
  navigate: NavigateFunction | null,
};

export const sagaMiddleware = createSagaMiddleware<sagaContextType>({
  context: {
    history,
    navigate: null,
  },
});

export const rootReducer = combineReducers({
  ...reducers,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(
    sagaMiddleware,
    logger,
  )),
);

sagaMiddleware.run(rootSaga);


export default store;
