import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "@/globals/utils/redux";
import {HttpError, HttpResponse} from "@/globals/types/http";

import * as types from '../types/customer';

export const ENTITY_NAME = 'customers/SET_STAFF_CUSTOMER_ASSIGNMENTS';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.StaffCustomerAssignmentsPayload, HttpResponse<any>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
