import axios from 'axios';

import {parseAppName} from '~globals/utils/http';
import * as types from '../types/routine';

export async function fetchRoutines(params: types.RoutineListParam) {
  const {appName, ..._params} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/routines/`, {params: _params});
}

export async function fetchRoutineActions(params: types.RoutineActionListParam) {
  const {appName, ..._params} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/routine-actions/`, {params: _params});
}

export async function fetchCustomerRoutines(params: types.CustomerRoutineListParam) {
  const {appName, ..._params} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/customer-routines/`, {params: _params});
}

export async function lockCustomerRoutines(payload: types.CustomerRoutineLockPayload) {
  const {appName, ..._payload} = payload;
  const prefix = parseAppName(appName);
  return axios.patch(`/@${prefix}/customer-routines/lock/`, _payload);
}

export async function deleteCustomerRoutineAction(params: types.CustomerRoutineDeletePayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.delete(
    `/@${prefix}/customer-routines/actions/`,
    {params: data},
  );
}

export async function addCustomerRoutineAction(params: types.AddCustomerRoutinePayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.post(`/@${prefix}/customer-routines/actions/`, data);
}

export async function addActionToRoutines(params: types.AddActionToRoutinePayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.post(`/@${prefix}/routines/actions/`, data);
}

export async function createRoutine(params: types.CreateRoutinePayload | types.CreateRoutineMinimumPayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.post(`/@${prefix}/routines/`, data);
}

export async function deleteRoutineAction(params: types.DeleteRoutineActionPayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.delete(`/@${prefix}/routines/actions/`, {params: data});
}

export async function modifyRoutineAction(params: types.ModifyRoutineActionPayload) {
  const {appName, ...data} = params;
  const prefix = parseAppName(appName);
  return axios.patch(`/@${prefix}/routine-actions/${data.slug}/`, data);
}
