import React from 'react';
import {
  Button,
} from 'antd';
import {ButtonProps} from 'antd/lib/button';

import styles from './Basic.module.scss';

export const PrimaryButton: React.FC<ButtonProps> = ({children, ...props}) => {
  return (
    <Button
      className={styles.buttonPrimary}
      type="primary"
      htmlType="submit"
      {...props}
    >{children}</Button>
  );
};
