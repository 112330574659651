
export type UserOrganization = {
  organization: Organization;
  role: UserOrganizationRole;
  createdAt: string | Date;
  updatedAt: string | Date;
}
export type AuthenticationResponse = {
  token: string;
}
export type Organization = {
  uid: string;
  name: string;
  appName: string;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export enum UserOrganizationRole {
  OWNER = 0,
  ADMIN = 10,
  MEMBER = 100,
  NONE = -1,
}

export type User = {
  username: string;
  organizations: UserOrganization[];
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isActive: boolean;
  dateJoined: string | Date;
}
export type LoginResponse = {
  username: string;
}
