import {ActionType as AT, createAction} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError} from "~globals/types/http";

import * as types from '../types/routine';

export const ENTITY_NAME = 'skinschool/DELETE_CUSTOMER_ROUTINE_ACTION';

export const RESET_ACTION_STATE_NAME = 'skinschool/RESET_ACTION_STATE_NAME';

export const resetResetActionState = createAction(RESET_ACTION_STATE_NAME)<void>();

export type ResetActionStateActionType = AT<typeof resetResetActionState>;

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<types.CustomerRoutineDeletePayload, types.PayloadDeleteSuccess, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
