import { RootState } from '@/store';

const $rootAuth = (state: RootState) => state.authentication;

export const $cafe24 = (state: RootState) => $rootAuth(state).cafe24;
export const $mall = (state: RootState) => $cafe24(state).mall;
export const $malls = (state: RootState) => $mall(state).malls;
export const $currentMall = (state: RootState) => $malls(state)[0];
export const $currentMallApp = (state: RootState) => $currentMall(state)?.application;

export const $auth = (state: RootState) => $cafe24(state).auth;
export const $authUri = (state: RootState) => $auth(state).authUri;
export const $requiredOAuthAuthorization = (state: RootState) => $auth(state).requiredOAuthAuthorization;

export const $customer = (state: RootState) => $cafe24(state).customer;
export const $customerList = (state: RootState) => $customer(state).customers;
