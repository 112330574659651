import { put, call } from 'redux-saga/effects';

import * as authActions from '../actions/auth';
import * as userActions from '../actions/user.detail';
import * as api from '../apis/authentications';

export function* fetchUserDetail$(action: ReturnType<typeof userActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchUserDetail, payload);

    yield put(authActions.addAuth(result.data));
  } catch (e) {
    yield put(userActions.action.failure(e));
    console.log(e);
  }
}
