import {all} from 'redux-saga/effects';

import globalsReducers from '../globals/reducers';
import globalsSagas from '../globals/sagas';
import authenticationsReducers from './authentications/reducers';
import authenticationsSagas from './authentications/sagas';
import customerReducers from './customers/reducers';
import customerSagas from './customers/sagas';
import skinschoolReducers from './skinschool/reducers';
import skinschoolSagas from './skinschool/sagas';

export function* rootSaga() {
  yield all([
    globalsSagas(),
    authenticationsSagas(),
    customerSagas(),
    skinschoolSagas(),
  ]);
}

export default {
  globals: globalsReducers,
  authentication: authenticationsReducers,
  customer: customerReducers,
  skinschool: skinschoolReducers,
};
