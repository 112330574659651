import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {
  Layout,
  Typography,
  Spin,
  Image,
} from 'antd';
import {
  ArrowLeftOutlined,
} from '@ant-design/icons';

import * as styles from '@/styles/globals';
import PageBreadcrumb from '@/containers/PageBreadcrumb';
import AppCheckList from '@/components/AppCheckList/AppCheckList';
import CustomerRoutines from '@/containers/CustomerRoutines';
import CustomerSummary from '@/components/customer/CustomerSummary';

import {useCustomerDetail} from '@/hooks/customer';
import {useAppName} from '@/modules/authentications/hooks/auth';

const CustomerDetail: React.FC = () => {
  const { uid } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isAppChecklistVisible, setIsAppChecklistVisible] = useState(false);
  const appName = useAppName();
  const {customer, error, isFetching} = useCustomerDetail({
    appName,
    uid,
  });

  useEffect(() => {
    setIsLoading(isFetching || (!customer && !error));
  }, [customer, error, isFetching]);

  if (isLoading) return <Spin size="large"/>;

  if (error) {
    return (
      <Layout><Typography.Title>{error.status}</Typography.Title></Layout>
    );
  }

  const onClickAppChecklist = () => {
    setIsAppChecklistVisible(true);
  };

  return (
    <Container>
      {!!customer && (
        <PageBreadcrumb title={<PageTitleContainer name={customer?.extra?.privacy?.name}
                                                   onClick={() => navigate('../')} />} />
      )}

      <MainContainer>
        {!customer && !isLoading && (
          <Typography.Title level={1}>존재하지 않는 고객입니다.</Typography.Title>
        )}

        {!customer?.extra?.privacy && !isLoading && (
          <Typography.Title level={1}>쇼핑몰 데이터와 동기화 되지 않은 고객입니다.</Typography.Title>
        )}

        {customer?.extra?.privacy && (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <SectionTitle level={2}>회원 정보</SectionTitle>
              <CustomerSummary style={{padding: '0 40px'}}
                customer={customer}
              />
            </div>
            <Image preview={false} onClick={onClickAppChecklist} src="/images/service_skinschool/btn-customerChecklist.png" />
          </div>
        )}
      </MainContainer>

      {!!customer?.extra?.privacy && appName && (
        <AppCheckList
          appName={appName}
          width={342}
          customer={customer}
          visible={isAppChecklistVisible}
          onClose={() => setIsAppChecklistVisible(false)}
        />)}

      {!!customer && (
        <MainContainer>
          <CustomerRoutines customer={customer} />
        </MainContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PageTitle = styled(Typography.Title)`
  ${styles.text.header22}
  color: ${styles.color.gray9};
  margin: 0 !important;
`;

const SectionTitle = styled(Typography.Title)`
  ${styles.text.header20}
  color: ${styles.color.gray9};
`;

const MainContainer = styled.div`
  background-color: ${styles.color.white};
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  padding: 27px 24px 20px;
`;

const PageTitleContainer = ({name, onClick}: {name: string | undefined | null, onClick: () => void}) => (
  <>
    <ArrowLeftOutlined style={{marginRight: '12px'}} onClick={onClick} />
    <PageTitle level={1}>{name || ''} 회원</PageTitle>
  </>
);

export default CustomerDetail;
