import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {rgba} from 'polished';
import {
  Layout,
  Menu,
  Typography,
} from 'antd';
import {
  default as AntIcon,
  HomeOutlined as IconHomeOutlined,
  TeamOutlined as IconTeamOutlined,
  ProfileOutlined as IconProfileOutlined,
} from '@ant-design/icons';
import {findLastIndex} from 'lodash';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import {$breadCrumbsRoute} from '@/globals/selectors/route';
import {useUser} from '@/modules/authentications/hooks/auth';

import {getBreadCrumbs} from '@/routes';

type MenuItem = {
  href: string;
  title: string;
  icon?: typeof AntIcon;
  children?: MenuItem[];
};

const items: MenuItem[] = [
  {
    href: '/desk',
    icon: IconHomeOutlined,
    title: '홈',
  },
  {
    href: '/desk/customers',
    icon: IconTeamOutlined,
    title: '고객목록',
    children: [
      {
        href: '/desk/customers/@all',
        title: '전체 고객목록',
      },
      {
        href: '/desk/customers/@assigned',
        title: '커스텀 고객목록',
      },
    ],
  },
  {
    href: '/desk/routine-actions',
    icon: IconProfileOutlined,
    title: '루틴, 케어 관리',
    children: [
      {
        href: '/desk/routine-actions/main',
        title: '메인 케어 액션 관리',
      },
      {
        href: '/desk/routine-actions/sub',
        title: '서브 케어 액션 관리',
      },
    ],
  },
];

const NavBar: React.FC = () => {
  const [me] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const routes = useSelector($breadCrumbsRoute);

  const [selectedMenu, setSelectedMenu] = useState<string[] | null>(null);

  useEffect(() => {
    const index = findLastIndex(getBreadCrumbs(location.pathname), (route) => route.path.indexOf(':') === -1);
    setSelectedMenu(index === -1 ? ['/desk'] : [routes[index].path]);
  }, []);

  const openMenu = ['/desk/customers', '/desk/routine-actions'];

  return (
    <Container
      trigger={null}
      collapsible={true}
      collapsed={false}>
      <Logo
        src="/images/service_skinschool/skinschoolLogo.png"
        onClick={() => navigate('/desk')}
      />

      {me && (
        <UserContainer>
          <UserInfo>{me.lastName}{me.firstName} 매니저</UserInfo>
          <OrganizationInfo>{me.currentOrganization?.organization.name}팀</OrganizationInfo>
        </UserContainer>
      )}

      {selectedMenu && <MenuNav items={items} defaultSelectedKeys={selectedMenu} openKeys={openMenu} />}
    </Container>
  );
};

type MenuNavPropType = {
  items: MenuItem[];
  defaultSelectedKeys: string[];
  openKeys: string[];
};

const MenuNav: React.FC<MenuNavPropType> = ({
  items,
  defaultSelectedKeys,
  openKeys,
}) => {
  const navigate = useNavigate();

  const onClickMenu = (to: string) => {
    navigate(to);
  };

  const renderSubMenuItem = (items: MenuItem[], key: string) => (
    items.map((item, index) => (
      <Menu.Item
        icon={item.icon ? <item.icon /> : undefined} key={item.href || `${key}-${index}`}>
        <Typography.Link onClick={() => onClickMenu(item.href)}>{item.title}</Typography.Link>
      </Menu.Item>
    ))
  );
  return (
    <ManuContainer
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      openKeys={openKeys}
    >
      {items.map((item, index) => (
          !item.children
            ? (<Menu.Item icon={item.icon ? <item.icon /> : undefined} key={item.href || index.toString()}>
                <Typography.Link onClick={() => onClickMenu(item.href)}>{item.title}</Typography.Link>
            </Menu.Item>)
            : (
              <Menu.SubMenu icon={item.icon ? <item.icon /> : undefined} key={item.href || index.toString()} title={item.title}>
                {renderSubMenuItem(item.children, index.toString())}
              </Menu.SubMenu>
            )
        )
      )}
    </ManuContainer>
  );
};

const Container = styled(Layout.Sider)`
  background-color: ${styles.color.primaryBlue} !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 244px !important;
  max-width: 244px !important;
  min-width: 244px !important;
  padding: 30px 0;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: 200px;
  cursor: pointer;
`;

const UserContainer = styled.div`
  position: relative;
  margin: 19px 20px 18px;
  height: auto;
  width: 204px;
  background-color: ${rgba(styles.color.gray4, 0.11)};
  padding: 9px 18px 6px;
  border-radius: 2px;
  border: 1px solid ${rgba(styles.color.gray4, 0.11)};
`;

const UserInfo = styled(Typography.Paragraph)`
  ${styles.text.header18}
  color: ${styles.color.white};
  margin: 0 !important;
`;
const OrganizationInfo = styled(Typography.Paragraph)`
  ${styles.text.body12}
  color: ${styles.color.white};
  margin: 0 !important;
`;

const ManuContainer = styled(Menu)`
  background-color: ${styles.color.primaryBlue} !important;
  width: 100%;
`;

export default NavBar;
