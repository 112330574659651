import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpPaginationResponse} from "~globals/types/http";
import {Cafe24Customer, Cafe24CustomerListParam} from '~modules/authentications/types/cafe24';

export const ENTITY_NAME = 'authentications/CAFE24_CUSTOMER_LIST';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<Cafe24CustomerListParam, HttpPaginationResponse<Cafe24Customer>, Error | HttpError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
