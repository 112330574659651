import {ActionType as AT, createAction} from "typesafe-actions";

import {BreadCrumbRouteType} from '../types/route';

export const MOVE_TO_PAGE = 'globals/route/MOVE_TO_PAGE' as const;

export const moveToPage = createAction(MOVE_TO_PAGE, action => (url: string) => action(url))();

export type MoveToPageType = AT<typeof moveToPage>;

export const UPDATE_BREADCRUMBS_ROUTE = 'globals/route/UPDATE_BREADCRUMBS_ROUTE' as const;

export const updateBreadCrumbsRoute = createAction(UPDATE_BREADCRUMBS_ROUTE)<BreadCrumbRouteType[]>();

export type UpdateBreadCrumbsRouteType = AT<typeof updateBreadCrumbsRoute>;

export type ActionType = MoveToPageType | UpdateBreadCrumbsRouteType;
