import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpPaginationResponse} from "~globals/types/http";

import * as types from '../types/routine';

export const ENTITY_NAME = 'skinschool/FETCH_ROUTINE_ACTION_LIST';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<
  types.RoutineActionListParam,
  HttpPaginationResponse<types.SimpleRoutineAction>,
  Error | HttpError
>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
