import React from 'react';
import {Col, Row} from 'antd';
import styled from 'styled-components';

import * as datetime from '~globals/utils/datetime';
import * as types from '~modules/customers/types';
import * as styles from '~styles/globals';

type PropType = {
  customer: types.Customer;
} & React.ComponentProps<any>;

const CustomerSummary: React.FC<PropType> = ({
  customer,
  ...props
}) => {
  const {extra, order} = customer;

  return (
    <div {...props}>
      <Row>
        <Col span={12}>
          <Row>
            <Col><Label>고객 이름</Label></Col>
            <Col><Text>{extra?.privacy?.name}</Text></Col>
          </Row>
          <Row>
            <Col><Label>까페24 ID</Label></Col>
            <Col><Text>{customer.username}</Text></Col>
          </Row>
          <Row>
            <Col><Label>고객 등급</Label></Col>
            <Col><Text>{extra?.privacy?.group}</Text></Col>
          </Row>
          <Row>
            <Col><Label>채널톡 ID</Label></Col>
            <Col><Text>{customer.uid}</Text></Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row>
            <Col span={6}><Label>마지막 결제일</Label></Col>
            <Col span={18}><Text>{datetime.format(order.latest?.order.orderDate)}</Text></Col>
          </Row>
          <Row>
            <Col span={6}><Label>마지막 주문번호</Label></Col>
            <Col span={18}>
              {order.latest?.order.orderId ? (
                <LinkText
                  href={makeCafe24OrderDetailLink(order.latest.order.orderId)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.latest.order.orderId}
                </LinkText>
              ) : '-'}
            </Col>
          </Row>
          <Row>
            <Col span={6}><Label>주문내역</Label></Col>
            <Col span={18}><Text>{truncateProductNames(order.latest?.items) || '-'}</Text></Col>
          </Row>
          <Row>
            <Col span={6}><Label>배송현황</Label></Col>
            <Col span={18}><Text>{getStatusName(order.latest?.status?.code)}</Text></Col>
          </Row>

        </Col>

      </Row>
    </div>
  );
};

const truncateProductNames = (items: null | undefined | any[]) => {
  if (!items || !items.length) { return null; }
  const rest = items.length > 1 ? ` 외 ${items.length-1}건` : '';
  return `${items[0].productName}${rest}`;
};

const getStatusName = (value: string | null | undefined) => {
  switch (value) {
    case 'new-order':
      return '주문요청';
    case 'ready-for-delivery':
      return '배송 준비';
    case 'on-delivery':
      return '배송 중';
    case 'withhold':
      return '취소/보류/환불';
    case 'shipped':
      return '배송완료';
    case 'done':
      return '완료';
    default:
      return value;
  }
};

const makeCafe24OrderDetailLink = (orderId: string) => {
  return `https://equalskin.cafe24.com/admin/php/shop1/s_new/order_detail.php?order_id=${orderId}&menu_no=74`;
};

const Label = styled.div`
  ${styles.text.header14};
  width: 100px !important;
  color: ${styles.color.gray10};
`;

const Text = styled.div`
  display: block;
  width: 300px !important;
  ${styles.text.body14};
  color: ${styles.color.gray8};
`;

const LinkText = Text.withComponent('a');

export default CustomerSummary;
