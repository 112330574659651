import React from 'react';
import styled from 'styled-components';

import * as styles from '@/styles/globals';

interface PropType {
  authUri: string;
}

const Cafe24AuthorizationError: React.FC<PropType> = ({authUri}) => {
  return (
    <Container>
      <div className="wrapper">
        <img src="/images/service_skinschool/check-circle.svg" className="checked-image" alt="" />
        <Message>토큰이 만료되어<br />카페24 앱 인증을 해야합니다.</Message>
        <ButtonSubmit href={authUri}>인증하러 가기</ButtonSubmit>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  
  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${styles.color.white};
    margin: 20px;
    width: calc(100% - 40px);
    min-height: 741px;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .checked-image {
      width: 72px;
      height: 72px;
    }
  }
`;

const Message = styled.div`
  ${styles.text.header22}
  color: ${styles.color.gray9};
  padding: 24px 0 32px;
`;

const ButtonSubmit = styled.a`
  ${styles.text.body14}
  background-color: ${styles.color.primaryBlue};
  border-radius: 2px;  
  padding: 5px 16px;
  text-align: center;
  color: ${styles.color.white};
`;

export default Cafe24AuthorizationError;
