import * as types from '../types/routine';

export const RoutineListState: types.RoutineListState = {
  count: 0,
  items: [],
  isFetching: null,
};

export const RoutineActionListState: types.RoutineActionListState = {
  count: 0,
  items: [],
};

export const CustomerRoutineListState: types.CustomerRoutineListState = {
  count: 0,
  items: [],
};

export const CustomerRoutineActionDeleteState: types.CustomerRoutineActionDeleteState = {
  error: null,
  item: null,
  isDeleting: false,
};

export const RoutineActionAddState: types.RoutineActionAddState = {
  error: null,
  isAdding: false,
};

export const RoutineCreateState: types.RoutineCreateState = {
  error: null,
  item : null,
  isCreating: false,
};

export const RoutineModifyState: types.RoutineModifyState = {
  error: null,
  item : null,
  isModifying: false,
};
