import {all, takeLatest} from 'redux-saga/effects';

import * as routineListActions from '../actions/routine.list';
import * as routineCreateActions from '../actions/routine.create';
import * as routineActionListActions from '../actions/routineaction.list';
import * as routineActionAddActions from '../actions/routineaction.add';
import * as routineActionRemoveActions from '../actions/routineaction.delete';
import * as customerRoutineListActions from '../actions/customerroutine.list';
import * as customerRoutineLockActions from '../actions/customerroutine.lock';
import * as customerRoutineActionDeleteActions from '../actions/customerroutineaction.delete';
import * as customerRoutineActionAddActions from '../actions/customerroutineaction.add';
import * as routineActionModifyActions from '../actions/routineaction.modify';
import * as sagas from '../sagas/routine';

export default function* () {
  yield all([
    takeLatest(routineListActions.REQUEST, sagas.fetchRoutines$),
    takeLatest(routineCreateActions.minActions.REQUEST, sagas.createRoutine$),
    takeLatest(routineCreateActions.fullActions.REQUEST, sagas.createRoutine$),
    takeLatest(routineActionListActions.REQUEST, sagas.fetchRoutineActions$),
    takeLatest(customerRoutineListActions.REQUEST, sagas.fetchCustomerRoutines$),
    takeLatest(customerRoutineActionDeleteActions.REQUEST, sagas.deleteCustomerRoutineAction$),
    takeLatest(customerRoutineActionAddActions.REQUEST, sagas.addCustomerRoutineAction$),
    takeLatest(routineActionAddActions.REQUEST, sagas.addActionToRoutine$),
    takeLatest(routineActionRemoveActions.REQUEST, sagas.deleteRoutineAction$),
    takeLatest(routineActionModifyActions.REQUEST, sagas.modifyRoutineAction$),
    takeLatest(customerRoutineLockActions.REQUEST, sagas.lockCustomerRoutine$),
  ]);
}
