import {createReducer} from 'typesafe-actions';
import {produce} from 'immer';

import * as models from '../models/auth';
import * as actions from '../actions/auth';
import {AuthState} from '~modules/authentications/states/auth';

export const initialState: AuthState = {
  current: null,
  authentications: [],
}

export default createReducer<AuthState, actions.ActionType>(initialState)
  .handleAction(actions.removeAuth, (state, action: actions.RemoveAuthActionType) => {
    const {payload} = action;
    if (state.authentications.findIndex(o => o.username === payload) === -1) return state;
    const authentications = state.authentications.filter(o => o.username !== payload);

    return produce(state, draft => {
      draft.current = authentications[authentications.length-1] || null;
      draft.authentications = authentications;
    });
  })
  .handleAction(actions.addAuth, (state, action: actions.AddAuthActionType) => {
    const {payload} = action;
    return produce(state, draft => {
      // const user = deserialize<models.User>(payload, models.User);
      const user = new models.User(payload);
      draft.current = user;
      draft.authentications = [
        ...state.authentications.filter(o => o.username !== user.username),
        user,
      ];
    });
  });
