import { put, call } from 'redux-saga/effects';

import * as mallListActions from '../actions/cafe24.mall.list';
import * as authActions from '../actions/cafe24.authorization';
import * as customerListActions from '../actions/cafe24.customer.list';
import * as api from '../apis/cafe24';

export function* fetchMalls$(action: ReturnType<typeof mallListActions.action.request>) {
  const {payload} = action;
  if (payload === undefined) return;
  try {
    const result = yield call(api.fetchMalls, payload);

    yield put(mallListActions.action.success(result));
  } catch (e) {
    yield put(mallListActions.action.failure(e));
    console.log(e);
  }
}

export function* checkAppAuthorization$(action: ReturnType<typeof authActions.checkAuthorization.action.request>) {
  const {payload} = action;
  if (!payload) return;
  try {
    const result = yield call(api.checkAppAuthorization, payload);

    yield put(authActions.checkAuthorization.action.success(result));
  } catch (e) {
    yield put(authActions.checkAuthorization.action.failure(e));
  }
}

export function* fetchAuthorizationUri$(action: ReturnType<typeof authActions.authorizationUri.action.request>) {
  const {payload} = action;
  if (!payload) return;
  try {
    const result = yield call(api.fetchAuthorizationUri, payload);

    yield put(authActions.authorizationUri.action.success(result));
  } catch (e) {
    yield put(authActions.authorizationUri.action.failure(e));
  }
}

export function* fetchCustomers$(action: ReturnType<typeof customerListActions.action.request>) {
  const {payload} = action;
  if (!payload) return;
  try {
    const result = yield call(api.fetchCustomers, payload);

    yield put(customerListActions.action.success(result));
  } catch (e) {
    yield put(customerListActions.action.failure(e));
  }
}
