import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

const CommonLayout: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default CommonLayout;
