import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {
  Space,
  Typography,
  Pagination,
  notification,
} from 'antd';
import {
  PlusCircleOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

import * as styles from '@/styles/globals';
import * as routineCreateActions from '@/modules/skinschool/actions/routine.create';
import * as routineActionAddActions from '@/modules/skinschool/actions/routineaction.add';
import {
  $routineList,
  $isRoutineListFetching,
  $routineActionAddError,
  $routineActionAddAdding,
  $routineCreateItem,
  $routineCreateError,
} from '@/modules/skinschool/selectors/routines';
import * as types from '@/modules/skinschool/types';
import AddActionToRoutine from '@/components/skinschool/AddActionToRoutine';
import PageBreadcrumb from '@/containers/PageBreadcrumb';
import {AddButton} from '@/components/common/buttons';
import {useAppName} from '@/modules/authentications/hooks/auth';
import {useRoutineActionList} from '@/hooks/routine';

import TableMenu from './TableMenu';
import Table from './Table';

type PropType = {
  routinePriority: 'primary' | 'secondary';
};

const RoutineActionList: React.FC<PropType> = ({
  routinePriority,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageLimit);
  const [term, setTerm] = useState('');
  const [isVisibleAddActionModal, setIsVisibleAddActionModal] = useState(false);

  const isRoutineListFetching = useSelector($isRoutineListFetching);
  const isRoutineActionAdding = useSelector($routineActionAddAdding);
  const routineActionAddError = useSelector($routineActionAddError);
  const routineCreateItem = useSelector($routineCreateItem);
  const routineCreateError = useSelector($routineCreateError);
  const routines = useSelector($routineList).filter((o) => o.priority === routinePriority);

  const appName = useAppName();
  const {items, count} = useRoutineActionList({
    appName,
    page,
    term,
    priority: routinePriority,
    limit: pageSize,
  });

  useEffect(() => {
    dispatch(routineCreateActions.resetCreateStateAction());
  }, []);

  useEffect(() => {
    if (!routineActionAddError) {return;}
    nodifyError('액션을 루틴에 추가하지 못했습니다.', routineActionAddError);
  }, [routineActionAddError]);

  useEffect(() => {
    if (routineCreateError) {
      nodifyError('루틴을 생성하지 못했습니다.', routineCreateError);
    } else if (routineCreateItem) {
      notification.success({
        message: '루틴을 추가했습니다',
      });
    }
  }, [routineCreateItem, routineCreateError]);

  const onSearch = () => {
    if (!term || term.length < 2) { return; }
    setTerm(term);
  };

  const resetPage = () => {
    setTerm('');
    setPage(1);
  }

  const onSubmitAddAction = (payload: types.AddActionToRoutinePayload) => {
    if (!appName) { return; }
    dispatch(routineActionAddActions.action.request({
      ...payload,
      appName,
    }));
    setIsVisibleAddActionModal(false);
  };

  const onSubmitCreateRoutine = (name: string, checkKind: types.CheckKindType) => {
    if (!appName) { return; }
    dispatch(routineCreateActions.minActions.action.request({
      appName,
      name,
      priority: routinePriority,
      checkKind: checkKind,
    }));
  };

  return (
    <Container>
      <PageBreadcrumb
        title={pageTitleByPriority[routinePriority]}
      />

      <MainContainer>
        <TableMenu onSearch={onSearch}
                   onChange={(value) => setTerm(value)}
                   onReset={resetPage}>
          <AddButton
            icon={<AddButtonIcon />}
            onClick={() => setIsVisibleAddActionModal(true)}>
            케어 액션 추가
          </AddButton>
        </TableMenu>

        <Table items={items}
               page={page}
               pageSize={pageSize}
               onNotifyError={(title, error) => nodifyError(title, error)}/>

        <Pagination
          pageSize={pageSize}
          defaultCurrent={page}
          total={count}
          onChange={(page, pageSize) => {
            setPage(page);
            if (pageSize) {
              setPageSize(pageSize);
            }
          }}
        />

        <AddActionToRoutine
          routinePriority={routinePriority}
          visible={isVisibleAddActionModal}
          initialRoutine={routineCreateItem}
          onSubmitCreateRoutine={onSubmitCreateRoutine}
          isLoading={isRoutineActionAdding}
          routines={routines}
          onCancel={() => setIsVisibleAddActionModal(false)}
          onSubmit={(value) => onSubmitAddAction(value)}
        />
      </MainContainer>
    </Container>
  );
};

const nodifyError = (title: string, error: types.ErrorState) => {
  const errData = error.data;
  let msg = '';
  if (_.isArray(errData)) {
    msg = errData[0];
  } else if (_.isObject(errData)) {
    nodifyError(title, {
      ...error,
      data: (errData as any)[_.keys(errData)[0]],
    });
    return;
  } else {
    msg = errData;
  }
  notification.error({
    message: (
      <Space direction="vertical">
        <Typography.Text>{title}</Typography.Text>
        {msg && <Typography.Text>{msg}</Typography.Text>}
      </Space>
    ),
  });
};

const defaultPageLimit = 12;

const pageTitleByPriority = {
  primary: '메인 루틴 액션 관리',
  secondary: '서브 루틴 액션 관리',
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  background-color: ${styles.color.white};
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  padding: 27px 24px 20px;
`;

const AddButtonIcon = styled(PlusCircleOutlined)`
  background-color: ${styles.color.green};
  color: ${styles.color.primaryBlue};
  ${styles.text.body16}
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;

export default RoutineActionList;
