import {combineReducers} from 'redux';

import auth from './auth';
import login from './login';
import cafe24MallList from './cafe24.mall.list';
import cafe24AppAuthorization from './cafe24.app.checkauthorization';
import cafe24Customer from './cafe24.customer';
import {AuthState} from '~modules/authentications/states/auth';
import {Cafe24AppAuthState, Cafe24CustomerState, Cafe24MallState} from '~modules/authentications/types/cafe24';

export type State = {
  auth: AuthState;
  cafe24: {
    mall: Cafe24MallState;
    auth: Cafe24AppAuthState;
    customer: Cafe24CustomerState;
  };
}

export default combineReducers({
  auth,
  login,
  cafe24: combineReducers({
    mall: cafe24MallList,
    auth: cafe24AppAuthorization,
    customer: cafe24Customer,
  }),
});
