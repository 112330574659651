import {ActionType as AT} from "typesafe-actions";
import {createAsyncActionEntity} from "~globals/utils/redux";
import {HttpError, HttpResponse} from "~globals/types/http";
import {
  Cafe24ApplicationAuthorization,
  Cafe24CheckApplicationAuthorization
} from '~modules/authentications/types/cafe24';

export const CHECK_APP_AUTHORIZATION_ENTITY_NAME = 'authentications/CHECK_CAFE24_APP_AUTHORIZATION';

export const checkAuthorization = createAsyncActionEntity<
  string,
  HttpResponse<Cafe24CheckApplicationAuthorization>,
  HttpError<Cafe24CheckApplicationAuthorization>
>(CHECK_APP_AUTHORIZATION_ENTITY_NAME);

export type CheckAuthorizationActionType = AT<typeof checkAuthorization.action>;

export const AUTHORIZATION_URI_ENTITY_NAME = 'authentications/AUTHORIZATION_URI_ENTITY_NAME';

export const authorizationUri = createAsyncActionEntity<
  string,
  HttpResponse<Cafe24ApplicationAuthorization>,
  HttpError<any>
  >(AUTHORIZATION_URI_ENTITY_NAME);

export type AuthorizationUriActionType = AT<typeof authorizationUri.action>;

export type ActionType = CheckAuthorizationActionType | AuthorizationUriActionType;
