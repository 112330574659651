import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {
  Col,
  Row,
  Space,
  Tabs,
  Typography,
  Modal,
  notification,
  message,
  Radio,
} from 'antd';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';
import _ from 'lodash';

import * as styles from '@/styles/globals';
import {useUser} from '~modules/authentications/hooks/auth';
import {
  $routineList,
  $customerRoutineList,
  $customerRoutineActionDelete,
  $customerRoutineActionDeleteError,
  $customerRoutineActionDeleteDeleting,
} from '~modules/skinschool/selectors/routines';
import * as types from '~modules/skinschool/types';
import * as customerTypes from '~modules/customers/types';
import * as routineActions from '~modules/skinschool/actions/routine.list';
import * as customerRoutineActions from '~modules/skinschool/actions/customerroutine.list';
import * as customerRoutineActionDeleteActions from '~modules/skinschool/actions/customerroutineaction.delete';
import * as customerRoutineActionAddActions from '~modules/skinschool/actions/customerroutineaction.add';
import * as customerRoutineLockActions from '~modules/skinschool/actions/customerroutine.lock';

import {AddButton} from '~components/common/buttons';
import AddRoutineForm, {OnChangePayload} from '~components/skinschool/AddRoutineForm';
import CustomerRoutineSection from '../components/customer/CustomerRoutineSection';

type PropType = {
  customer: customerTypes.Customer;
};

const CustomerRoutines: React.FC<PropType> = ({
  customer,
  ...props
}) => {
  const dispatch = useDispatch();

  const [currentMenu, setCurrentMenu] = useState('primary-routine');
  const [user,] = useUser();
  const [appName, setAppName] = useState<null | string>(null);

  useEffect(() => {
    const _appName = user?.currentOrganization?.organization?.appName || null;
    if (!_appName) { return; }

    setAppName(_appName);
    dispatch(customerRoutineActions.action.request({
      appName: _appName,
      customer: customer.uid,
    }));

    dispatch(routineActions.action.request({
      appName: _appName,
    }));
  }, [customer, user]);

  useEffect(() => {
    dispatch(customerRoutineActionDeleteActions.resetResetActionState());
  }, [])

  const onChangeMenu = (e: any) => {
    setCurrentMenu(e.key);
  };

  if (!appName) { return <></>}

  return (
    <TabContainer
      defaultActiveKey={currentMenu}
      onChange={(key: string) => onChangeMenu}>
      {_.keys(menu).map((key: string) => (
        <Tabs.TabPane tab={menu[key].name} key={key}>
          <RoutineTabPanel
            appName={appName}
            customer={customer.uid}
            menuItem={menu[key]}
          />
        </Tabs.TabPane>))}
    </TabContainer>
  );
};

type RoutineTabPanelPropType = {
  appName: string;
  customer: string;
  menuItem: RoutineMenuItemType;
};

const RoutineTabPanel: React.FC<RoutineTabPanelPropType> = (props) => {
  const {menuItem, appName, customer} = props;

  const dispatch = useDispatch();

  const routineList = useSelector($routineList);
  const customerRoutines = useSelector($customerRoutineList);
  const itemDeletingAction = useSelector($customerRoutineActionDelete);
  const actionDeleteError = useSelector($customerRoutineActionDeleteError);
  const isActionDeleting = useSelector($customerRoutineActionDeleteDeleting);

  const [routines, setRoutines] = useState<types.CustomerRoutine[]>([]);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [selectedRoutineAction, setSelectedRoutineAction] = useState<null | types.RoutineAction>(null);
  const [addableRoutines, setAddableRoutines] = useState<types.Routine[]>([]);
  const [isVisibleAddRoutineModal, setIsVisibleAddRoutineModal] = useState(false);
  const [addRoutinePayload, setAddRoutinePayload] = useState<null | OnChangePayload>(null);

  useEffect(() => {
    const currentRoutineSet = new Set();
    const result = customerRoutines.filter((routine) => {
      if (menuItem.routines && menuItem.routines.has(routine.routine.slug)) {
        currentRoutineSet.add(routine);
        return true;
      }
      if (!menuItem.routines && !exceptionalSecondaryMenus.has(routine.routine.slug)) {
        currentRoutineSet.add(routine);
        return true;
      }
      return false;
    });
    setRoutines(result);

    setAddableRoutines(
      routineList.filter((o) => !currentRoutineSet.has(o) && o.priority === menuItem.priority)
    );
  }, [customerRoutines, menuItem]);

  useEffect(() => {
    if (!isActionDeleting && itemDeletingAction && !actionDeleteError) {
      notification.open({
        message: '액션을 제거했습니다.',
      });
      setSelectedRoutineAction(null);
      setIsVisibleDeleteModal(false);
    }
    if (!isActionDeleting && !itemDeletingAction && actionDeleteError) {
      notification.open({
        message: <DeleteErrorMessage message={actionDeleteError.data.toString()} />,
      });
      setSelectedRoutineAction(null);
      setIsVisibleDeleteModal(false);
    }
  }, [isActionDeleting, itemDeletingAction, actionDeleteError]);

  const onOpenDeleteModal = (routineAction: types.RoutineAction) => {
    if (isActionDeleting === true) {return;}
    setSelectedRoutineAction(routineAction);
    setIsVisibleDeleteModal(true)
  };
  const onDeleteRoutineAction = () => {
    if (!selectedRoutineAction || isActionDeleting === true) {return;}
    dispatch(customerRoutineActionDeleteActions.action.request({
      appName,
      customer,
      routine: selectedRoutineAction.routine,
      action: selectedRoutineAction.slug,
    }));
  };

  const onAddRoutine = () => {
    if (!addRoutinePayload) {
      return;
    }
    const {isDateValid, routine, action, openedAt, closedAt} = addRoutinePayload;
    if (!isDateValid) {
      message.error('날짜가 유효하지 않습니다.');
      return;
    }
    if (!routine || !action) {
      message.error('루틴과 액션을 선택하지 않았습니다.');
      return;
    }
    dispatch(customerRoutineActionAddActions.action.request({
      appName,
      customer,
      routine,
      action,
      openedAt,
      closedAt,
    }));
    setIsVisibleAddRoutineModal(false);
  };

  const [suncareLockingStatus, setSuncareLockingStatus] = useState<null | boolean>(null);
  useEffect(() => {
    const routine = customerRoutines.find((o) => o.routine.slug === 'sun-care');
    if (routine) {
      setSuncareLockingStatus(routine.lockingReason === null);
    }
  }, [customerRoutines]);
  const sunCareRadioGroupOptions = [
    {
      label: '썬케어 비활성화',
      value: false,
    },
    {
      label: '썬케어 활성화',
      value: true,
    },
  ];
  const onChangeSunCareActivation = (routine: string, value: boolean) => {
    dispatch(customerRoutineLockActions.action.request({
      appName,
      customer,
      routine,
      lockingReason: value ? null : '상담사 선생님과 피부 경과를 보고 곧 쓰게 되실 거에요 :)',

    }));
    setSuncareLockingStatus(value);
  };

  return (
    <PanelContainer>
      <PanelHeader>
        <Col span={19}>
          <PanelTitleContainer>
            <Typography.Title className="title" level={3}>{menuItem.name}</Typography.Title>
            <Typography.Text className="description">{menuItem.desc}</Typography.Text>
          </PanelTitleContainer>
        </Col>
        <Col span={5}>
          <Space>
            {/*<Button>저장 후 고객앱 전송</Button>*/}
          </Space>
        </Col>
      </PanelHeader>

      {menuItem.priority === 'secondary' && !menuItem.routines && (
        <CustomerRoutineAddContainer>
          <Space>
            <Typography.Title className="title" level={4}>커스텀 케어 추가</Typography.Title>
            <Typography.Text className="description">고객에게 새로운 케어를 추가합니다.</Typography.Text>
          </Space>
          <AddButton onClick={() => setIsVisibleAddRoutineModal(true)}>커스텀 케어 추가</AddButton>
        </CustomerRoutineAddContainer>
      )}
      <Modal
        title="커스텀 케어 추가"
        centered
        visible={isVisibleAddRoutineModal}
        onOk={onAddRoutine}
        okText="추가"
        onCancel={() => setIsVisibleAddRoutineModal(false)}
        cancelText="취소"
        width={820}
      >
        <AddRoutineForm
          onChange={setAddRoutinePayload}
          routines={addableRoutines} />
      </Modal>

      {routines.filter((o) => o.items.length).map((routine) => (
        routine.routine.slug !== 'sun-care' && (
          <CustomerRoutineSection
            appName={appName}
            customer={customer}
            key={routine.routine.slug}
            routine={routine}
            onDelete={onOpenDeleteModal}
          />
        ) ||
        routine.routine.slug === 'sun-care' && (
          <Radio.Group
            key={routine.routine.slug}
            options={sunCareRadioGroupOptions}
            onChange={(e) => onChangeSunCareActivation(routine.routine.slug, e.target.value)}
            value={suncareLockingStatus}
            optionType="button"
            buttonStyle="solid"
          />
        )
      ))}

      <Modal
        title={<ModalTitle title="선택한 액션을 삭제하시겠습니까?"/>}
        centered
        visible={isVisibleDeleteModal}
        onOk={onDeleteRoutineAction}
        okText="예, 삭제합니다"
        onCancel={() => setIsVisibleDeleteModal(false)}
        cancelText="취소"
      >
        <p>선택된 액션이 완전히 삭제됩니다.</p>
      </Modal>
    </PanelContainer>
  );
};

type ModalTitlePropType = {
  title: string;
};

const ModalTitle: React.FC<ModalTitlePropType> = ({title}) => {
  return (
    <Space>
      <CloseCircleOutlined />
      <Typography.Text>{title}</Typography.Text>
    </Space>
  );
};

type DeleteErrorMessagePropType = {
  message: string;
};

const DeleteErrorMessage: React.FC<DeleteErrorMessagePropType> = ({message}) => {
  return (
    <Space direction="vertical">
      <Typography.Title level={4}></Typography.Title>
      <Typography.Text>{message}</Typography.Text>
    </Space>
  );
};

const menu: MenuType = {
  primaryRoutine: {
    name: '회원 루틴 액션 관리',
    desc: '회원의 기상, 취침 루틴 액션을 관리합니다',
    priority: 'primary',
    routines: new Set(['MORNING_MAIN_ROUTINE', 'EVENING_MAIN_ROUTINE']),
  },
  secondaryOthers: {
    name: '회원 케어 액션 관리',
    desc: '',
    priority: 'secondary',
    routines: null,
  },
  secondarySuncareOilcare: {
    name: '썬케어, 기름종이 케어 관리',
    desc: '',
    priority: 'secondary',
    routines: new Set(['sun-care', 'oilpaper-care']),
  },
};

const exceptionalSecondaryMenus: Set<string> = new Set(
  _.flatten(_.values(menu).map((o) => o.routines ? Array.from(o.routines!) : []))
);

type RoutineMenuItemType = {
  name: string;
  desc: string;
  priority: string;
  routines: null | Set<string>;
};
type MenuType = {
  [key: string]: RoutineMenuItemType;
};

const TabContainer = styled(Tabs)`
  width: 100%;
`;

const PanelContainer = styled.div`
  width: 100%;
`;

const PanelHeader = styled(Row)`
  margin: 0 0 30px 0;
`;

const PanelTitleContainer = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .title {
    ${styles.text.header20}
    color: ${styles.color.gray9};
    margin: 0 !important;
  }
  .description {
    ${styles.text.body14}
    color: ${styles.color.gray7};
  }
`;

const CustomerRoutineAddContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 54px;
  
  .title {
    ${styles.text.header18}
    color: ${styles.color.gray9};
  }
  .description {
    ${styles.text.body14}
    color: ${styles.color.gray7};
  }
`;

export default CustomerRoutines;
