import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useRoutes, useNavigate, useLocation} from 'react-router-dom';

import routes, {getBreadCrumbs} from './routes';
import {updateBreadCrumbsRoute} from '@/globals/actions/route';

import './styles/globals.scss';
import {sagaMiddleware} from './store';

const App: React.FC = () => {
  const routing = useRoutes(routes);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const items = getBreadCrumbs(location.pathname);
    if (items && items.length) {
      dispatch(updateBreadCrumbsRoute(items));
    }
  }, [location.pathname]);

  useEffect(() => {
    sagaMiddleware.setContext({navigate});
  }, []);

  return (
    <>
      {routing}
    </>
  );
}

export default App;
