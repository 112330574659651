import {combineReducers} from 'redux';

import * as types from '../types/routine';

import routineList from './routine.list';
import routineCreate from './routine.create';
import routineActionList from './routineaction.list';
import routineActionAdd from './routineaction.add';
import routineActionModify from './routineaction.modify';
import customerRoutineList from './customerroutine.list';
import customerRoutineActionDelete from './customerroutineaction.delete';

export type State = {
  routineList: types.RoutineListState;
  routineCreate: types.RoutineCreateState;
  routineActionList: types.RoutineActionListState;
  routineActionAdd: types.RoutineActionAddState;
  routineActionModify: types.RoutineModifyState;
  customerRoutineList: types.CustomerRoutineListState;
  customerRoutineActionDelete: types.CustomerRoutineActionDeleteState;
}

export default combineReducers({
  routineList,
  routineCreate,
  routineActionList,
  routineActionAdd,
  routineActionModify,
  customerRoutineList,
  customerRoutineActionDelete,
});
