import { RootState } from '@/store';

const $rootAuth = (state: RootState) => state.skinschool;

export const $routineList = (state: RootState) => $rootAuth(state).routineList.items;
export const $routineListCount = (state: RootState) => $rootAuth(state).routineList.count;
export const $isRoutineListFetching = (state: RootState) => $rootAuth(state).routineList.isFetching;

export const $routineActionList = (state: RootState) => $rootAuth(state).routineActionList.items;
export const $routineActionListCount = (state: RootState) => $rootAuth(state).routineActionList.count;

export const $customerRoutineList = (state: RootState) => $rootAuth(state).customerRoutineList.items;
export const $customerRoutineListCount = (state: RootState) => $rootAuth(state).customerRoutineList.count;

export const $customerRoutineActionDelete = (state: RootState) => $rootAuth(state).customerRoutineActionDelete.item;
export const $customerRoutineActionDeleteError = (state: RootState) => $rootAuth(state).customerRoutineActionDelete.error;
export const $customerRoutineActionDeleteDeleting = (state: RootState) => $rootAuth(state).customerRoutineActionDelete.isDeleting;

export const $routineActionAddError = (state: RootState) => $rootAuth(state).routineActionAdd.error;
export const $routineActionAddAdding = (state: RootState) => $rootAuth(state).routineActionAdd.isAdding;

export const $routineCreateItem = (state: RootState) => $rootAuth(state).routineCreate.item;
export const $routineCreateError = (state: RootState) => $rootAuth(state).routineCreate.error;
export const $routineCreateCreating = (state: RootState) => $rootAuth(state).routineCreate.isCreating;

export const $routineActionModifyItem = (state: RootState) => $rootAuth(state).routineActionModify.item;
export const $routineActionModifyError = (state: RootState) => $rootAuth(state).routineActionModify.error;
export const $routineActionModifyModifying = (state: RootState) => $rootAuth(state).routineActionModify.isModifying;
