import {combineReducers} from 'redux';

import customerList from './customer.list';
import {CustomerListState as CustomerListStateType} from '../types/customer';

import customerDetail from './customer.detail';
import {CustomerDetailState as CustomerDetailStateType} from '../types/customer';

import diaryDetail from './diary.detail';
import {DiaryDetailState as DiaryDetailStateType} from '../types/diary';

export type State = {
  list: CustomerListStateType;
  detail: CustomerDetailStateType;
  diaryDetail: DiaryDetailStateType;
}

export default combineReducers({
  list: customerList,
  detail: customerDetail,
  diaryDetail: diaryDetail,
});
