import {ActionType as AT} from "typesafe-actions";
import {AxiosError, AxiosResponse} from "axios";
import {createAsyncActionEntity} from "~globals/utils/redux";

export const ENTITY_NAME = 'authentications/LOGOUT';

export const {
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} = createAsyncActionEntity<string, AxiosResponse<null>, Error | AxiosError>(ENTITY_NAME);

export type ActionType = AT<typeof action>;
