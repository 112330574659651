import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';

import {parseAppName} from '~globals/utils/http';
import * as types from '../types/customer';

export async function fetchCustomers(params: types.CustomerListParam) {
  const {appName, ..._params} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/customers/`, {params: snakecaseKeys(_params)});
}

export async function fetchCustomerDetail(params: types.CustomerDetailParam) {
  const {appName, uid} = params;
  const prefix = parseAppName(appName);
  return axios.get(`/@${prefix}/customers/${uid}/`);
}

export async function syncCustomerCafe24(params: types.SyncCustomerCafe24Param) {
  const {mallProvider, uid} = params;
  return axios.post(`/${mallProvider}/fetch-data/sync-customer/${uid}/`);
}

export async function setCustomerAssignments(params: types.StaffCustomerAssignmentsPayload) {
  const {appName, customers} = params;
  const prefix = parseAppName(appName);
  return axios.put(`/@${prefix}/staff-customer-assignment/assignments/`, {customers});
}
