import {all, takeLatest} from "redux-saga/effects";

import * as routeActions from "../actions/route";
import {moveToPage$} from "../sagas/route";

export default function* () {
  yield all([
    takeLatest(routeActions.MOVE_TO_PAGE, moveToPage$),
  ]);
}
