import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {StateHttpError} from '@/globals/types/http';
import {buildPaginationParams} from '@/globals/utils/http';
import * as routineActionListactions from '@/modules/skinschool/actions/routineaction.list';
import {
  $routineActionList,
  $routineActionListCount,
  $routineActionModifyError,
  $routineActionModifyItem,
} from '@/modules/skinschool/selectors/routines';
import * as types from '@/modules/skinschool/types';
import * as routineActionModifyActions from '~modules/skinschool/actions/routineaction.modify';

interface RoutineActionListPropType extends Pick<types.RoutineActionListParam, 'term' | 'page' | 'limit'> {
  appName: string | null;
  priority: 'primary' | 'secondary';
}

interface RoutineActionListReturnType {
  items: types.SimpleRoutineAction[];
  count: number;
}

export const useRoutineActionList = (params: RoutineActionListPropType): RoutineActionListReturnType => {
  const dispatch = useDispatch();
  const {appName, priority, page, limit, term} = params;
  const items = useSelector($routineActionList);
  const count = useSelector($routineActionListCount);

  useEffect(() => {
    if (!appName) { return; }
    dispatch(routineActionListactions.action.request(buildPaginationParams({
      ...params,
      appName,
    })));
  }, [appName, priority, page, limit, term]);

  return {
    items,
    count,
  };
};

interface RoutineActionModifyPropType {
  appName: string | null;
  slug: null | string | undefined;
}

type RoutineActionModifyPayloadType = Pick<types.ModifyRoutineActionPayload, Exclude<keyof types.ModifyRoutineActionPayload, 'appName'>>;

interface RoutineActionModifyReturnType {
  item: null | types.SimpleRoutineAction | types.RoutineAction;
  error: null | StateHttpError;
  setItem: (payload: RoutineActionModifyPayloadType) => void;
}

export const useRoutineActionModify = (params: RoutineActionModifyPropType): RoutineActionModifyReturnType => {
  const dispatch = useDispatch();
  const {appName, slug} = params;

  const routineActionModifyItem = useSelector($routineActionModifyItem);
  const routineActionModifyError = useSelector($routineActionModifyError);
  const setItem = (payload: RoutineActionModifyPayloadType) => {
    if (!appName || !slug) { return; }
    dispatch(routineActionModifyActions.action.request({
      ...payload,
      slug,
      appName,
    }));
  };

  return {
    item: routineActionModifyItem,
    error: routineActionModifyError,
    setItem,
  };
};
