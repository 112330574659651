import React from 'react';
import {Outlet} from 'react-router-dom';
import {Layout} from 'antd';
import styled from 'styled-components';

import * as styles from '@/styles/globals';
import Copyright from '@/components/common/Copyright';
import NavBar from './NavBar';
import TopBar from './TopBar';

type PropType = {
  requiredAuth?: boolean;
};

const MainLayout: React.FC<PropType> = ({ requiredAuth }) => {
  return (
    <Layout>
      <NavBar/>

      <Layout>
        <Header>
          <TopBar />
        </Header>

        <Content>
          <Outlet />

          <Layout.Footer>
            <Copyright />
          </Layout.Footer>
        </Content>
      </Layout>

    </Layout>
  );
};

const Header = styled(Layout.Header)`
  height: initial;
  padding: 0;
`;

const Content = styled(Layout.Content)`
  background-color: ${styles.color.gray2};
  height: 100vh;
  width: 100%;
  padding: 0;
`;

export default MainLayout;
