import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Input,
  Table,
  notification,
} from 'antd';
import {
  DisconnectOutlined,
  DownloadOutlined,
  EditOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import * as datetime from '@/globals/utils/datetime';
import * as types from '@/modules/skinschool/types';
import {SimpleRoutineAction} from '@/modules/skinschool/types';
import * as routineActionDeleteActions from '@/modules/skinschool/actions/routineaction.delete';
import {useAppName} from '@/modules/authentications/hooks/auth';
import {useRoutineActionModify} from '@/hooks/routine';

interface PropType {
  items: SimpleRoutineAction[];
  page: number;
  pageSize: number;
  onNotifyError: (title: string, error: types.ErrorState) => void;
}

const Container: React.FC<PropType> = (
  {
    items,
    page,
    pageSize,
    onNotifyError,
  }
) => {
  const dispatch = useDispatch();

  const appName = useAppName();
  const [isEditing, setIsEditing] = useState(false);
  const [editingAction, setEditingAction] = useState<null | types.SimpleRoutineAction>(null);
  const [editingActionPayload, setEditingActionPayload] = useState<null | types.ModifyRoutineActionPayload>(null);
  const modifyRoutineAction = useRoutineActionModify({
    appName,
    slug: editingAction?.slug || null,
  });

  const makeRowNumber = useCallback((index: number) => {
    return (page-1) * pageSize + index + 1;
  }, [page, pageSize]);

  const onDetachRoutineAction = (action: types.SimpleRoutineAction) => {
    if (!appName) { return; }
    dispatch(routineActionDeleteActions.action.request({
      appName,
      routine: action.routine.slug,
      action: action.slug,
    }));
  };

  const onSubmitRoutineAction = () => {
    modifyRoutineAction.setItem({
      ...editingActionPayload,
    });
  };

  const onChangeEditableCell = (key: string, value: string) => {
    setEditingActionPayload({
      ...(editingActionPayload || {}),
      appName: appName || '',
      [key]: value,
    });
  };

  useEffect(() => {
    if (modifyRoutineAction.error) {
      onNotifyError('루틴 액션을 수정하지 못했습니다.', modifyRoutineAction.error);
    } else if (modifyRoutineAction.item) {
      notification.success({
        message: '루틴 액션을 수정했습니다',
      });
      setIsEditing(false);
    }
  }, [modifyRoutineAction.item, modifyRoutineAction.error]);

  return (
    <Table<types.SimpleRoutineAction>
      style={{width: '100%', margin: '12px 9px 20px 11px'}}
      dataSource={items}
      pagination={false}
      rowKey={(row) => `${row.routine.slug}-${row.slug}`}>
      <Table.Column title="번호" render={(_, __, index) => <div>{makeRowNumber(index)}</div>} />

      <Table.Column title="루틴 제목" dataIndex={['routine', 'name']} />

      <Table.Column
        title="액션 제목"
        dataIndex={['name']}
        render={(value, record: types.SimpleRoutineAction) => {
          return <EditableCell payloadKey="name"
                               value={value}
                               onChange={onChangeEditableCell}
                               onPressEnter={onSubmitRoutineAction}
                               payload={editingActionPayload}
                               isEditting={isEditing && editingAction && record.slug === editingAction.slug} />;
        }}
      />

      <Table.Column
        title="액션 설명"
        dataIndex={['description']}
        render={(value, record: types.SimpleRoutineAction) => {
          return <EditableCell payloadKey="description"
                               value={value}
                               onChange={onChangeEditableCell}
                               onPressEnter={onSubmitRoutineAction}
                               payload={editingActionPayload}
                               isEditting={isEditing && editingAction && record.slug === editingAction.slug} />;
        }}
      />

      <Table.Column
        title="추가된 날짜"
        dataIndex={['createdAt']}
        render={(value) => <div>{datetime.format(value, defaultDatetimeFormat)}</div>}
      />

      <Table.Column
        title={isEditing ? '저장' : '수정'}
        render={(__, record) => <Button
          style={{border: 'none'}}
          disabled={isEditing ? editingAction!.slug !== (record as any).slug : false}
          icon={isEditing ? <DownloadOutlined /> : <EditOutlined />}
          onClick={() => {
            if (isEditing) {
              return onSubmitRoutineAction();
            }
            setEditingActionPayload({
              ...editingActionPayload,
              appName: appName || '',
              name: (record as any).name,
              routine: (record as any).routine.slug,
              description: (record as any).description,
            });
            setIsEditing(true);
            setEditingAction(record as any);
          }} />}
      />

      <Table.Column
        title={isEditing ? '수정취소' : '비활성화'}
        render={(__, record) => <Button
          style={{border: 'none'}}
          disabled={isEditing ? editingAction!.slug !== (record as any).slug : false}
          onClick={() => isEditing ? setIsEditing(false) : onDetachRoutineAction(record as any)}
          icon={isEditing ? <UndoOutlined /> : <DisconnectOutlined />} />}
      />
    </Table>
  );
};

const defaultDatetimeFormat = 'YYYY-MM-DD';

interface EditableCellPropType {
  value: string;
  isEditting: boolean | null;
  payload: null | types.ModifyRoutineActionPayload;
  payloadKey: keyof types.ModifyRoutineActionPayload;
  onChange: (key: string, value: string) => void;
  onPressEnter: () => void;
}

const EditableCell: React.FC<EditableCellPropType> = (props) => {
  const {payloadKey, value, isEditting, payload, onChange, onPressEnter} = props;
  return (
    isEditting === true
      ? <Input
        value={payload ? payload[payloadKey] : null}
        onChange={(e) => onChange(payloadKey, e.target.value)}
        onPressEnter={() => onPressEnter()} />
      : <div>{value}</div>
  );
};

export default Container;
