import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';

import {buildNextUrl} from '~globals/utils/http';
import {getStorageData} from '~globals/utils/storage.local';
import {AUTH_TOKEN_STORAGE_KEY} from '~globals/utils/storage.keys';

import {action as userDetailAction} from '~modules/authentications/actions/user.detail';
import * as cafe24MallListAction from '~modules/authentications/actions/cafe24.mall.list';


type PropType = {
  next?: string;
};

const AuthRequired: React.FC<PropType> = ({ next , ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [token, setToken] = useState<null | string>(getStorageData(AUTH_TOKEN_STORAGE_KEY));

  useEffect(() => {
    setToken(getStorageData(AUTH_TOKEN_STORAGE_KEY));
  }, []);

  useEffect(() => {
    if (!token) {
      const _next = buildNextUrl(next, location);
      navigate(`/login?next=${_next}`, {replace: true});
      return;
    }
    dispatch(userDetailAction.request(null));
    setIsVisible(true);
  }, [location.pathname, token]);

  useEffect(() => {
    if (!token) { return; }
    dispatch(cafe24MallListAction.action.request(null));
  }, [token]);

  return (
    <>
      {isVisible && props.children}
    </>
  );
};

export default AuthRequired;
