
import {delStorageData} from '~globals/utils/storage.local';
import {AUTH_TOKEN_STORAGE_KEY} from '~globals/utils/storage.keys';
import * as actions from '../actions/logout';

export function* logout$(action: ReturnType<typeof actions.action.request>) {
  try {
    yield delStorageData(AUTH_TOKEN_STORAGE_KEY);
    yield window.location.href = '/';
  } catch (e) {
    console.log(e);
  }
}
