import {ActionType as AT, createAction} from "typesafe-actions";
import {User} from '~modules/authentications/types/auth';

export const ENTITY_NAME = 'authentications/AUTH';

export const ADD_AUTH = `${ENTITY_NAME}_ADD`;
export const addAuth = createAction(ADD_AUTH)<User>();
export type AddAuthActionType = AT<typeof addAuth>;

export const REMOVE_AUTH = `${ENTITY_NAME}_REMOVE`;
export const removeAuth = createAction(REMOVE_AUTH)<string>();
export type RemoveAuthActionType = AT<typeof removeAuth>;

export type ActionType = AddAuthActionType | RemoveAuthActionType;
