import { Serializable, JsonProperty } from 'typescript-json-serializer';

import {
  User as UserType,
  UserOrganization as UserOrganizationType,
  UserOrganizationRole as EnumUserOrganizationRole
} from '~modules/authentications/types/auth';

@Serializable()
export class User implements UserType {
  @JsonProperty() username: string;
  @JsonProperty() email: string | null;
  @JsonProperty() firstName: string | null;
  @JsonProperty() lastName: string | null;
  @JsonProperty() isActive: boolean;
  @JsonProperty() dateJoined: Date;
  @JsonProperty() organizations: UserOrganizationType[];

  constructor(payload: UserType) {
    this.username = payload.username;
    this.email = payload.email;
    this.firstName = payload.firstName;
    this.lastName = payload.lastName;
    this.isActive = payload.isActive;
    this.dateJoined = new Date(payload.dateJoined);
    this.organizations = payload.organizations;
  }

  get currentOrganization(): UserOrganizationType | null {
    return this.organizations.length ? this.organizations[0] : null;
  }

  get currentOrganizationRoleName(): string {
    const role = this.currentOrganization?.role;
    return role ? EnumUserOrganizationRole[role] : EnumUserOrganizationRole.NONE.toString();
  }
}
