import { put, call, getContext } from 'redux-saga/effects';

import globalSettings from '~globals/settings';
import {setStorageData} from '~globals/utils/storage.local';
import {setupTokenAuthClient} from '~globals/utils/http';
import {AUTH_TOKEN_STORAGE_KEY} from '~globals/utils/storage.keys';

import * as loginActions from '../actions/login';
import * as userDetailActions from '../actions/user.detail';
import * as api from '../apis/authentications';

export function* login$(action: ReturnType<typeof loginActions.action.request>) {
  const {payload: {next, ..._payload}} = action;
  if (!_payload) return;
  try {
    const result = yield call(api.postAuthentication, _payload);

    yield put(loginActions.action.success(result));
    yield put(userDetailActions.action.request(null))

    const token = result.data.token;
    setStorageData(AUTH_TOKEN_STORAGE_KEY, token);
    setupTokenAuthClient(token);
    const navigate = yield getContext('navigate');
    yield navigate(next || globalSettings.routes.defaultPathAfterAuth);
  } catch (e) {
    yield put(loginActions.action.failure(e));
    console.log(e);
  }
}
