import {createReducer} from 'typesafe-actions';

import {produce} from 'immer';
import {HttpError, HttpResponse} from "~globals/types/http";

import * as actions from '../actions/cafe24.authorization';
import {
  Cafe24AppAuthState,
  Cafe24ApplicationAuthorization,
  Cafe24CheckApplicationAuthorization
} from '~modules/authentications/types/cafe24';

export const initialState: Cafe24AppAuthState = {
  tokens: [],
  requiredOAuthAuthorization: null,
  authUri: null,
};

export default createReducer<Cafe24AppAuthState, actions.ActionType>(initialState)
  .handleAction(actions.checkAuthorization.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    const data = (payload as HttpResponse<Cafe24CheckApplicationAuthorization>).data;

    return produce(state, draft => {
      draft.requiredOAuthAuthorization = data.code !== 'available';
    });
  })
  .handleAction(actions.checkAuthorization.action.failure, (state, action: actions.ActionType) => {
    const {payload} = action;
    const data = (payload as HttpError<Cafe24CheckApplicationAuthorization>).response?.data;

    return produce(state, draft => {
      if (!data) {
        draft.requiredOAuthAuthorization = null;
      } else {
        draft.requiredOAuthAuthorization = true;
      }
    });
  })
  .handleAction(actions.authorizationUri.action.success, (state, action: actions.ActionType) => {
    const {payload} = action;
    const data = (payload as HttpResponse<Cafe24ApplicationAuthorization>).data;

    return produce(state, draft => {
      draft.authUri = data.uri;
    });
  });
